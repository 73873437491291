<div class="grid-container" [ngClass]="{'overflow-auto': rowData.length}">
  <table class="sgrid-data">
    <tr>
      <th *ngFor="let h of columnDefs">{{h.headerName | translate}}</th>
    </tr>
    <tr *ngFor="let row of rowData">
      <td *ngFor="let h of columnDefs">{{h.valueFormatter? h.valueFormatter(row): row[h.field]}}</td>
    </tr>
  </table>

  <div class="msg-container">
    <span *ngIf="isLoading" class="spinner">
      <div class="sgrid-loader"></div>
    </span>
    <span [ngClass]="{'error-msg': errorMsg}">{{getMessage() | translate}}</span>
  </div>
</div>