import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, debounce, timer } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EnvService } from 'src/app/services/env/env.service';
import { UploadService } from 'src/app/services/upload/upload.service';
import { EventParam, LoginInfo } from 'src/app/shared/models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-case-number',
  templateUrl: './case-number.component.html',
  styleUrls: ['./case-number.component.css']
})
export class CaseNumberComponent implements OnDestroy {

  @Output() afterValidate = new EventEmitter<string>();

  enableCaseBtn = true
  caseNumberCtrl = new UntypedFormControl(null, [
    Validators.required,
    Validators.minLength(10),
    Validators.maxLength(10),
    Validators.pattern('^[0-9]*$')
  ]);
  errorMsg = ''
  loading = false
  private $subscriptions: Subscription[]
  private userId = ''

  constructor(private upload: UploadService, private env: EnvService, private auth: AuthService, private translate: TranslateService,) {
    this.$subscriptions = [upload.$uploadStatus.subscribe((p: EventParam) => {
      if (p.type === 'upload_success') {
        this.caseNumberCtrl.reset(null, { emitEvent: false })
      }
    })]
    this.caseNumberCtrl.valueChanges.pipe(debounce(() => timer(600))).subscribe(() => {
      this.errorMsg = ''
      this.afterValidate.next('')
    })
    this.$subscriptions.push(
      this.auth.$loginInfo.subscribe({
        next: (user: LoginInfo) => {
          this.userId = user?.uid || ''
        }
      })
    )
  }

  validate(): void {
    // Clear previous error message
    this.errorMsg = '';
    const caseNumber = this.caseNumberCtrl.value
    this.loading = true

    this.upload.loadCaseDetails(caseNumber, this.userId).subscribe({
      next: (res) => {
        this.loading = false
        if (!res) {
          this.errorMsg = 'sfu_no_case_detail_found'
          this.upload.loggingService({
            userName: this.userId,
            caseNumber,
            Message: 'No case detail found',
            type: 'error',
          });
        } else {
          this.afterValidate.next(caseNumber);
          this.upload.loggingService({
            userName: this.userId,
            caseNumber:caseNumber,
            Message: 'Successfully validated case number',
            type: 'info',
          });
        }
      },
      error: (err) => {
        this.errorMsg = 'sfu_load_case_validation_fails';
        if (
          err.status === 401 &&
          err.error?.message.includes('not a secure')

        ) {
          this.errorMsg = 'sfu_not_a_secured_case';
          let url = `${this.env.environment.nafuURL}sg`
          let errorMsg  = `${this.translate.instant('sfu_no_secured_user_msg')} ${url}. ${this.translate.instant('sfu_no_secured_user_msg1')}`
          alert(errorMsg);
          this.upload.loggingService({
             userName: this.userId,
             caseNumber:caseNumber,
             Message: errorMsg, 
             type: 'error'
            })
          this.redirectToNafu()
        } else {
          this.errorMsg = err.error?.message;
          this.upload.loggingService({ 
            userName: this.userId, 
            caseNumber:caseNumber, 
            Message: this.errorMsg, 
            type: 'error'
          })
        }
        this.loading = false;
      }
    })
  }

  uploadDashboardUrl(): string {
    return this.env.environment.nafuURL
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => s.unsubscribe())
  }
  redirectToNafu(): void {
    let url = `${this.env.environment.nafuURL}sg`
    window.location.assign(url)
  }
}
