import { Component, OnInit } from '@angular/core';
import { UploadService } from 'src/app/services/upload/upload.service';
import { DETAIL_COL_DEFINITION } from './config/col-definition';
import { TokenService } from 'src/app/services/token/token.service';
import { LoginInfo } from 'src/app/shared/models';
import * as jose from 'jose';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session/session.service';
@Component({
  selector: 'app-upload-summary',
  templateUrl: './upload-summary.component.html',
  styleUrls: ['./upload-summary.component.css']
})
export class UploadSummaryComponent implements OnInit {

  columnDefs = DETAIL_COL_DEFINITION;
  filterFileTypes: string[] = []

  filteredRowData = [];
  displayRowData = [];
  errorMsg = ''
  loading = true

  private originalRowData: any = null
  private paginationParam = {
    pageSize: 10,
    startIndex: 0
  }

  constructor(private upload: UploadService,private token: TokenService,private router: Router,private session: SessionService,) { }

  ngOnInit(): void {
    this.loading = true
    this.errorMsg = ''
  
      const info: LoginInfo = jose.decodeJwt(this.token.getAccessToken());
      let isInternal = info['isinternal'] === "true" ? true : false;
      const res = String(this.session.getItem('isTransDataRestricted'));
      let isTransDataRestricted = res === "true" ? true : false;
      if (isInternal) {
        if(!isTransDataRestricted){
        this.upload.getUploadSummary().subscribe({
          next: (res) => {
            this.originalRowData = res
            this.filteredRowData = res
            this.pupulateFilteTypes()
            this.columnDefs = this.buildColumnDefinitions(res); // Set columnDefs here
            this.applyPagination(this.paginationParam)
            this.loading = false
          },
          error: () => {
            this.errorMsg = 'sfu_upload_summary_error'
            this.loading = false
          }
        })
      }else{
        this.router.navigate(['/accessDenied']);
      }
      } else {
        this.router.navigate(['/']);
      }
    
  }

  filterData(param: any): void {
    this.filteredRowData = this.originalRowData

    if (param.startDate) {
      this.filteredRowData = this.filteredRowData.filter((d: any) => d._uploadStartTime >= param.startDate)
    }
    if (param.endDate) {
      this.filteredRowData = this.filteredRowData.filter((d: any) => d._uploadEndTime <= param.endDate)
    }
    if (param.caseNumber !== undefined && param.caseNumber !== '') {
      this.filteredRowData = this.filteredRowData.filter((d: any) => 
        d.case_number && d.case_number.startsWith(param.caseNumber)
      )
    }
    if (param.fileName) {
      this.filteredRowData = this.filteredRowData.filter((d: any) => 
        d.filename && d.filename.startsWith(param.fileName)
      )
    }
    this.applyPagination(this.paginationParam)
  }

  applyPagination(param: any): void {
    this.displayRowData = this.filteredRowData.slice(param.startIndex, param.startIndex + param.pageSize)
  }

  private pupulateFilteTypes(): void {
    const fileTypesMap = this.originalRowData.reduce((map: any, row: any) => {
      map[row.fileType] = true
      return map
    }, {})
    this.filterFileTypes = Object.keys(fileTypesMap)
  }
  private buildColumnDefinitions(data: any[]): any[] {
    const hasUserId = data.some(row => row.userId);

    const colDefs = [...DETAIL_COL_DEFINITION]
    if (hasUserId) {
      colDefs.unshift({
        headerName: 'User ID',
        field: 'userId',
      })
    }
    return colDefs
  }

}
