import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EnvService } from 'src/app/services/env/env.service';
import { LoginInfo } from '../../models';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, OnDestroy {
  public fullYear: any;
  public relEServiceUrl = '/eservice/assistant';
  public relSupportAlertUrl = '/portal?_nfpb=true&_pageLabel=alertsummary_page';
  public relSupportOfferUrl = '/NOW/products/support/';
  public relSupportPoliciesUrl = '/info/web/ECMP1132185.html';
  public relSupportPolicyUrl = '/info/web/ECMP1651343.html';
  public absEServiceUrl = '';
  public absSupportAlertUrl = '';
  public absSupportOfferUrl = '';
  public absSupportPoliciesUrl = '';
  public absSupportPolicyUrl = '';

  loggedIn = false;
  $subscriptions: Subscription[]

  constructor(private auth: AuthService, private env: EnvService) {
    const environment = this.env.environment

    this.absEServiceUrl =
      environment.hdrFtrhostUrl + this.relEServiceUrl;
    this.absSupportAlertUrl =
      environment.hdrFtrhostUrl + this.relSupportAlertUrl;
    this.absSupportOfferUrl =
      environment.hdrFtrhostUrl + this.relSupportOfferUrl;
    this.absSupportPoliciesUrl =
      environment.hdrFtrhostUrl + this.relSupportPoliciesUrl;
    this.absSupportPolicyUrl =
      environment.hdrFtrhostUrl + this.relSupportPolicyUrl;

    this.$subscriptions = []
  }

  ngOnInit() {
    const date = new Date();
    this.fullYear = date.getFullYear();
    this.$subscriptions.push(
      this.auth.$loginInfo.subscribe({
        next: (user: LoginInfo) => {
          this.loggedIn = !!user?.name
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => s.unsubscribe())
  }
}