import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-get-token",
  templateUrl: "./access-denied.component.html",
})
export class AccessDeniedComponent implements OnInit {
  
  constructor() {}
  ngOnInit(): void {
    console.log("Access Denied");
  }
}
