import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AES, enc } from 'crypto-js';
import { Observable, map, tap } from 'rxjs';
import { TOKEN } from 'src/app/constants';
import { EnvService } from '../env/env.service';
import { SessionService } from '../session/session.service';


@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private KEY = 'klsdnvlashgthkdsalay459y3k4mbtertsc'

  constructor(
    private session: SessionService,
    private http: HttpClient,
    private env: EnvService) {
  }

  setTokens(obj: any): void {
    const encrypted = AES.encrypt(JSON.stringify(obj), this.KEY);
    this.session.setItem(TOKEN, encrypted.toString());
  }

  getAccessToken(): string {
    return this.getTokens() ? this.getTokens().access_token : '';
  }
  getRbacRes(token: string): Observable<any> {
    let url = `${this.env.environment.rbacUrl}?token=${token}`;
    return this.http.get(url).pipe(tap(res => console.log("received rbac response")));
}

  getRefreshToken(): string {
    return this.getTokens() ? this.getTokens().refresh_token : '';
  }

  getTokens(): any {
    let token: any;
    try {
      const session = String(this.session.getItem(TOKEN));
      const decrypted = AES.decrypt(session, this.KEY);
      token = JSON.parse(decrypted.toString(enc.Utf8));
    } catch (e) {
      token = '';
    }
    return token || '';
  }

  generate(code: string): Observable<any> {
    return this.http.post(this.env.environment.generateTokenUrl, {
      code,
      redirectUrl: this.env.environment.redirectUri
    }).pipe(map(this.handleTokenResponse()))
  }

  private handleTokenResponse(): any {
    return (res: any) => {
      if (res.data?.access_token) {
        this.setTokens(res.data);
        return res.data.access_token
      } else {
        this.invalidate();
      }
      return null
    }
  }

  refresh(): Observable<any> {
    return this.http.post(this.env.environment.refreshTokenUrl, {
      refresh_token: this.getRefreshToken()
    }).pipe(
      map(this.handleTokenResponse())
    )
  }

  invalidate(): void {
    this.session.removeItem(TOKEN)
  }
}
