import { HttpClient, HttpEvent, HttpEventType } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject, interval, map, of, switchMap, tap } from 'rxjs';
import { ApiResponse, EventParam, MultipartUploadProgress, PreSignedUrl, PreSignedUrlModel, SasUrlPayload, UploadParam, UploadSummary, UploadSummaryStatus,multipartMap,transDataPayload } from 'src/app/shared/models';
import { EnvService } from "../env/env.service";
import { TokenService } from '../token/token.service';
import { UtilsService } from "../utils/utils.service";


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  public $uploadStatus = new ReplaySubject<EventParam>(1)
  case_type:string='';
  subscription: any;
  POOLING_DELAY:number= 180000

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private env: EnvService,
    private util: UtilsService) { }

  getSasUrls(param: UploadParam): Observable<PreSignedUrlModel> {
    const data: SasUrlPayload = {
      caseNumber: param.caseNumber,
      fileSize: param.fileDetails.content.size,
      fileName: param.fileDetails.fileName,
      userId: param.loginInfo?.uid || '',
      partNumberList: param.partNumberList,
      uploadId: param.uploadId,
      unique_id:param.unique_id,
      maskedFileName:param.maskedFileName
    }
    if (param.fileDetails.hasMultiparts) {
      return this.getMultipartSasUrls(data)
    } else {
      return this.getSingleSasUrl(data)
    }
  }

  private getSingleSasUrl(param: SasUrlPayload): Observable<PreSignedUrlModel> {
    const { caseNumber, fileName, fileSize } = param
    return this.http.post(this.env.environment.getSasUrl, { caseNumber, fileName, fileSize }, {
      headers: { authorization: 'Bearer ' + this.token.getAccessToken() }
    }).pipe(
      map((res: any) => {
        return {
          preSignedUrls: [res.data] 
        } as PreSignedUrlModel;
      })
    );
  }

  private getMultipartSasUrls(param: SasUrlPayload): Observable<any> {
          return this.http.post(this.env.environment.getSasUrls, param, {
            headers: { authorization: 'Bearer ' + this.token.getAccessToken() }
          }).pipe(map((r: any) => r.data))
  }

  uploadFile(url: string, param: UploadParam): Observable<any> {
    let headers = {
      'x-ms-meta-casenumber': param.caseNumber,
      'x-ms-meta-userid': param.loginInfo?.uid || '',
      'Content-Type': "application/octet-stream"
    }

    return this.http.put(url, param.fileDetails.content, {
      headers,
      reportProgress: true,
      observe: 'events'
    })
  }

  uploadMultipartsFile(progress: MultipartUploadProgress, payload: UploadParam): Observable<any> {
    const initiatorSub = new Subject()
    const subject = new Subject()
    const tryUploadNext = () => {
      if (progress.canTriggerNext()) {
        progress.nextItem(initiatorSub, this);
      }else if(progress.allSucceeded()){
        this.subscription.unsubscribe();
      }
    };
    const upload = this.getUploadFn(progress, subject, tryUploadNext);

    this.updateMultipartSummary(payload, progress, UploadSummaryStatus.IN_PROGRESS);
    this.subscription = interval(this.POOLING_DELAY).subscribe(() => {
      console.log('Pooling service called');
      let poolingReq = {
        unique_id: progress.multipartSas.unique_id,
        masked_file_name: progress.multipartSas?.maskedFileName
      };
      this.poolingService(poolingReq,progress).subscribe((data:any) => {
        let poolingRes = data;
        this.loggingService({
          userName: payload.loginInfo?.uid,
          caseNumber: payload.caseNumber,
          fileName: payload.fileDetails.fileName,
          maskedFileName: progress.multipartSas?.maskedFileName,
          successCount: progress.successCount,
          totalCount: progress.totalCount,
          Message: 'Pooling service called',
          type: 'info',
        });
      });
    });

    initiatorSub.subscribe({
      next: (sas: any) => {
        const preSignedUrl: PreSignedUrl = sas
        this.util.getFilePart(payload.fileDetails.content as File, sas.partIndex || 0, progress.multipartSas.totalParts).subscribe({
          next: (blob: Blob) => {
            const subscription = upload(preSignedUrl, this.mapPayload(preSignedUrl, payload, blob))
            progress.addSubscription(sas.partNum, subscription)
          }
        })
      }
    });
    progress.nextItem(initiatorSub, this)

    return subject
  }

  private mapPayload(sas: PreSignedUrl, payload: UploadParam, blob: Blob): UploadParam {
    return Object.assign(
      { ...payload },
      {
        fileDetails: {
          ...payload.fileDetails,
          content: blob,
        },
        partNumber: (sas.partIndex || 0) + 1
      }
    )
  }

  private getUploadFn(progress: MultipartUploadProgress, subject: Subject<any>, tryUploadNext: any) {

    const upload = (sas: PreSignedUrl, payload: UploadParam) => {
      return this.uploadFile(sas.preSignedUrl, payload).subscribe({
        next: (e: HttpEvent<any>) => {
          switch (e.type) {
            case HttpEventType.Sent:
              tryUploadNext();
              break;
            case HttpEventType.Response:
              const etag = e.headers.get('ETag') ?? '';
              let multipartMap:multipartMap={ PartNumber:sas.partNum,ETag: etag }
              progress.markSuccessOf(sas,multipartMap);
              tryUploadNext();
              subject.next(progress);
              if (progress.allSucceeded()) {
                subject.complete();
              }
              // this.updateMultipartSummary(payload, progress);
          }
        },
        error: () => {
          subject.complete();
          progress.resetProgress();
          this.updateMultipartSummary(payload, progress, UploadSummaryStatus.FAILED);
        }
      });
    };
    return upload;
  }

  updateMultipartSummary(payload: UploadParam | null,
    progress: MultipartUploadProgress | null,
    status = UploadSummaryStatus.IN_PROGRESS) {

    if (!payload || !progress) {
      return
    }
      let percentage = Math.round(
        ((progress.successCount || 0) /
          (progress.totalCount || 1)) *
          100
      );
    let  percentageDone = Math.min(percentage, 100);
    const transferRate = this.util.getTransferRate(progress.startDate, progress.uploadedSizeInCurrentSession(payload.fileDetails.content.size))
    const param: any = {
      userId: payload.loginInfo?.uid,
      upload_status:status,
      upload_id:progress.multipartSas.uploadId,
      upload_start_time: progress.startDate,
      total_parts: progress.totalCount,
      no_parts_uploaded: progress.successCount,
      percentage_done: percentageDone,
      last_active_session: new Date(),
      unique_id:progress.multipartSas.unique_id,
      masked_file_name:progress.multipartSas.maskedFileName,
      upload_type: 'M',
    }
    if (transferRate) {
      param['upload_transfer_rate'] = transferRate
    }


    if ([UploadSummaryStatus.COMPLETED, UploadSummaryStatus.FAILED].includes(status)) {
      param['upload_end_time'] = new Date()
    }
    if(UploadSummaryStatus.CANCELLED === status){
      param['upload_cancel_time'] = new Date()
    }
    if(this.case_type.length>0){
      param['case_type'] = this.case_type;
    }
    this.updateUploadSummary(payload, param);
  }


  updateSingleUploadSummary(payload: UploadParam | null,
    sas: PreSignedUrl | null,
    startTime: Date,
    status = UploadSummaryStatus.IN_PROGRESS) {

    if (!payload) {
      return
    }
    const sasUrls = JSON.stringify(sas)

    // const transferRate = this.util.getTransferRate(startTime, payload.fileDetails.content.size)
    const param: any = {
      userId: payload.loginInfo?.uid,
      upload_status:status,
      upload_start_time: startTime,
      unique_id:sas?.unique_id,
      masked_file_name:sas?.maskedFileName,
      upload_type: 'S',
    }
    
    if ([UploadSummaryStatus.COMPLETED, UploadSummaryStatus.FAILED].includes(status)) {
      param['upload_end_time'] = new Date()
    }
    if(UploadSummaryStatus.CANCELLED === status){
      param['upload_cancel_time'] = new Date()
    }
    if(this.case_type.length>0){
      param['case_type'] = this.case_type;
    }
    this.updateUploadSummary(payload, param);
  }

  markMultipartUploadCompleted(param: any): Observable<any> {
    return this.http.post(this.env.environment.completeMultipartUploadUrl, param, {
      headers: { authorization: 'Bearer ' + this.token.getAccessToken() }
    })
  }

  cancelMultipartsUpload(param: any): Observable<any> {
    return this.http.post(this.env.environment.cancelMultipartUploadUrl, param, {
      headers: { authorization: 'Bearer ' + this.token.getAccessToken() }
    })
  }

  getUploadSummary(param: transDataPayload | null = null): Observable<any> {
    let url = this.env.environment.getLogsUrl
    let payload = {
      case_number:param?.caseNumber,
      userId:param?.userId,
      filename:param?.fileName,
      upload_start_time:param?.startDate,
      upload_end_time:param?.endDate
    }
    return this.http.post(url,payload, {
      headers: { authorization: 'Bearer ' + this.token.getAccessToken() }
    }).pipe(map(res => this.util.mapLogResponse(res as ApiResponse<UploadSummary[]>)))
  }

  validateSummary(summary: UploadSummary): boolean {
    const valid = !!(
      summary
      && summary.upload_start_time
      && ![
        UploadSummaryStatus.CANCELLED,
        UploadSummaryStatus.COMPLETED,
        UploadSummaryStatus.FAILED
      ].includes(summary.status)
      && this.util.ageInDays(new Date(summary.upload_start_time)) < 1
    )
    return valid;
  }

  loadCaseDetails(caseNumber: string, userId: string): Observable<any> {
    const url = this.env.environment.loadCaseDetailUrl + `?caseNumber=${caseNumber}&userId=${userId}`
    return this.http.get(url, {
      headers: { authorization: 'Bearer ' + this.token.getAccessToken() }
    }).pipe(
      tap((result: any) => {
        this.case_type = result['Case_Type'];
      })
    );
  }

  updateUploadSummary(param: UploadParam, otherParam: any): void {
    const summary: any = {
      //userId: param.loginInfo?.uid,
      filename: param.fileDetails.fileName,
      case_number: param.caseNumber,
      file_size: param.fileDetails.content.size,
      //file_type: param.fileDetails.contentType,
      //case_type: string,
      time_zone: this.util.timezone()
    }

    if (otherParam) {
      Object.keys(otherParam).forEach(k => {
        summary[k] = otherParam[k]
      })
    }

    this.http.post(this.env.environment.updateLogsUrl, summary, {
      headers: { authorization: 'Bearer ' + this.token.getAccessToken() }
    }).subscribe(() => { console.log('') })

  }
  getPrevFileUploadDetails(param:UploadParam): Observable<any> {
    const data: SasUrlPayload = {
      caseNumber: param.caseNumber,
      fileName: param.fileDetails.fileName,
      fileSize: param.fileDetails.content.size,
      userId: param.loginInfo?.uid || ''
    }
    return this.http.post(this.env.environment.getPreviousUploadDetails, data, {
      headers: { authorization: 'Bearer ' + this.token.getAccessToken() }
    }).pipe(map((r: any) =>r))
  }
  poolingService(param: any,progress: any): Observable<any> {
    let percentage = Math.round(
      ((progress.successCount || 0) /
        (progress.totalCount || 1)) *
        100
    );
  let  percentage_done = Math.min(percentage, 100);
    const data: any = {
      unique_id: param.unique_id,
      masked_file_name: param.masked_file_name,
      percentage_done: percentage_done,
    }
    return this.http.post(this.env.environment.setActiveSessionUrl, data, {
      headers: { authorization: 'Bearer ' + this.token.getAccessToken() }
    }).pipe(map((r: any) =>r))
  }
  loggingService(param: any): void {
    this.http.post(this.env.environment.loggingsUrl, param, {
      headers: { authorization: 'Bearer ' + this.token.getAccessToken() }
    }).subscribe(() => { console.log('') });
  }
}
