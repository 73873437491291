import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-upload-status-message',
  templateUrl: './upload-status-message.component.html',
  styleUrls: ['./upload-status-message.component.css']
})
export class UploadStatusMessageComponent implements OnChanges {
  @Input()
  uploadStatus: string | null = null;

  @Input()
  uploadMessage: string = '';

  ngOnChanges() {
  }
}
