<header class="n-site-header n-off-canvas-menu__content-wrap" *ngIf="userName">
  <div class="n-top-hat">
    <div class="n-container">
      <div class="n-top-hat__cross-property-nav">
        <ul class="n-top-hat__list">
          <li class="n-top-hat__list-item">
            <a href="http://www.netapp.com/us/index.aspx" class="n-top-hat__link">NetApp.com</a>
          </li>
          <li class="n-top-hat__list-item">
            <a href="http://mysupport.netapp.com/" class="n-top-hat__link">Support</a>
          </li>
          <li class="n-top-hat__list-item">
            <a href="http://community.netapp.com/" class="n-top-hat__link">Community</a>
          </li>
          <li class="n-top-hat__list-item">
            <a href="http://www.netapp.com/us/services-support/university/index.aspx"
              class="n-top-hat__link">Training</a>
          </li>
        </ul>
      </div>
      <div class="n-top-hat__utils">
        <ul class="n-top-hat__list">
          <li class="n-top-hat__list-item">
            <span class="n-user-name">
              <span id="userID-dt" class="n-top-hat__link-text">Welcome, {{ userName }}</span>
            </span>
          </li>
          <li class="n-top-hat__list-item">
            <a (click)="logout()" class="n-top-hat__link hand-symbol" title="Sign Out">
              <span class="n-top-hat__link-text">Sign-Out</span>
            </a>
          </li>
          <li class="n-top-hat__list-item">
            <a href="http://www.netapp.com/us/services-support/ngs-contacts.aspx" class="n-top-hat__link">
              <span class="n-top-hat__link-text">Contact Us</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="n-property-bar n-property-bar--v1">
    <div class="n-property-bar__inner-wrap">
      <div class="n-property-bar__menu-toggle">
        <a class="n-property-bar__menu-toggle-link-custom" (click)="toggleOffCanvas($event)">
          <span class="n-property-bar__menu-toggle-text">Menu</span>
          <svg class="n-icon-bars n-property-bar__menu-toggle-icon" aria-labelledby="title"
            xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1">
            <title>bars</title>
            <use xlink:href="/assets/svg/sprite.svg#bars"></use>
          </svg>
        </a>
      </div>
      <div class="n-property-bar__property-mark n-property-bar__property-mark--has-property-name">
        <a href="{{ absSupportURL }}" target="_blank" class="n-property-bar__property-link">
          <div class="n-property-bar__logo">
            <svg class="n-icon-netapp-mark n-property-bar__netapp-mark-icon" xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1792 1792" id="netapp-mark" width="100%" height="100%">
              <path d="M1100.155 1642.238V827.617H692.849v814.621H13.989V148.757h1765.025v1493.481h-678.859z"></path>
            </svg>

            <svg aria-labelledby="title" class="n-property-bar__logo-svg" viewBox="0 0 1008 181.537"
              xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" version="1.1">
              <title>NetApp</title>
              <use xlink:href="/assets/svg/sprite.svg#netapp-logo">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1008 181.536" id="netapp-logo" width="100%"
                  height="100%">
                  <path fill="#0067C5" d="M0 0v147.978h69.062V59.187h39.462v88.791h69.048V0z"></path>
                  <path fill="#010101"
                    d="M256.498.021h29.832l68.817 91.481V.021h31.898v147.937h-27.477l-71.159-93.403v93.411h-31.913L256.498.021zm146.066 92.327c0 34.43 24.973 58.139 58.786 58.139 20.335 0 35.446-7.979 45.908-21.004l-18.23-16.16c-8.81 8.199-16.571 11.562-27.265 11.562-14.3 0-24.342-7.559-27.497-22.037h78.266c.244-2.926.43-5.898.43-8.377 0-31.912-17.207-61.078-54.743-61.078-32.749 0-55.641 26.438-55.641 58.516v.439m31.247-9.455c2.507-14.453 11.121-23.932 24.379-23.932 13.401 0 21.812 9.639 23.69 23.932h-48.069zm99.045 33.149c0 26.027 13.216 33.793 32.694 33.793 10.707 0 18.512-2.518 25.225-6.51v-25.621c-4.632 2.559-9.87 4.041-15.766 4.041-7.106 0-10.268-3.596-10.268-10.943V62.743h26.456v-27.27h-26.456V6.726h-31.888v28.748H519.44v27.27h13.427l.003 53.298m225.878 65.494h31.866v-48.26c7.558 9.008 18.054 16.783 35.046 16.783 26.678 0 51.229-20.564 51.229-58.154v-.4c0-37.566-24.962-58.117-51.229-58.117-16.562 0-27.271 7.738-35.046 18.235V35.477h-31.866v146.059zm86.194-89.629c0 18.889-12.576 31.086-27.469 31.086-14.896 0-27.271-12.402-27.271-31.086v-.402c0-18.679 12.388-31.05 27.271-31.05 14.893 0 27.469 12.371 27.469 31.05v.402m44.912 89.629h31.895v-48.26c7.543 9.008 18.045 16.783 35.066 16.783 26.627 0 51.186-20.564 51.186-58.154v-.4c0-37.566-24.961-58.117-51.186-58.117-16.584 0-27.297 7.738-35.066 18.235V35.477h-31.895v146.059zm86.265-89.629c0 18.889-12.619 31.086-27.52 31.086-14.855 0-27.271-12.402-27.271-31.086v-.402c0-18.679 12.412-31.05 27.271-31.05 14.899 0 27.52 12.371 27.52 31.05v.402m-377.165 56.056h32.938l13.438-32.975h58.239l13.524 32.975h33.666L689.376.006h-29.051l-61.371 147.957zm55.965-61.459l19.534-47.701 19.513 47.701h-39.047z">
                  </path>
                </svg>
              </use>
            </svg>
          </div>
          <h2 class="n-property-bar__property-name">Support</h2>
        </a>
      </div>

      <div class="n-property-bar__narrow-actions">
        <ul class="n-property-bar__narrow-actions-list">
          <li class="n-property-bar__narrow-actions-list-item">
            <div class="n-property-bar__narrow-actions-dropdown js-object-property-nav-dropdown">
              <span>
                <svg class="n-icon-user n-icon n-icon--default" xmlns:xlink="http://www.w3.org/1999/xlink"
                  xmlns="http://www.w3.org/2000/svg" version="1.1">
                  <title>user</title>
                  <use xlink:href="/assets/svg/sprite.svg#user"></use>
                </svg>
              </span>
            </div>
          </li>
          <li class="n-property-bar__narrow-actions-list-item">
            <a href="/nowsmforms/logout.html" class="n-property-bar__narrow-actions-link">
              <span class="n-property-bar__narrow-actions-link-text">Sign-Out</span>
            </a>
          </li>

        </ul>
      </div>
      <div class="n-property-bar__narrow-action-search-form" #searchForm>
        <form class="n-search-form"></form>
      </div>
    </div>
  </div>
</header>

<!-- Left menu when expand in mobile view-->

<div class="n-off-canvas-menu__menu" *ngIf="userName">
  <div class="n-off-canvas-menu__menu-inner">
    <div class="n-off-canvas-menu__property-title">Support</div>

    <ul class="n-off-canvas-menu__property-navigation-list">
      <li class="n-property-navigation-bar__utils-list-item">
        <a href="/gchat/nss" target="_blank"
          class="n-property-navigation-bar__utils-link n-property-navigation-bar__utils-link--secondary js-behavior-toggle-chat">
          <svg class="n-icon-comment n-icon" aria-labelledby="title" xmlns:xlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg" version="1.1">
            <title>comment</title>
            <use xlink:href="/assets/svg/sprite.svg#comment"></use>
          </svg>
          <span class="n-property-navigation-bar__utils-link-text">Chat</span>
        </a>
      </li>
    </ul>

    <ul class="n-off-canvas-menu__global-navigation-list">
      <li class="n-off-canvas-menu__global-navigation-list-item">
        <a href="http://www.netapp.com/us/index.aspx" class="n-off-canvas-menu__global-navigation-link">Netapp.com</a>
      </li>

      <li class="n-off-canvas-menu__global-navigation-list-item">
        <a href="http://mysupport.netapp.com/" class="n-off-canvas-menu__global-navigation-link">Support</a>
      </li>

      <li class="n-off-canvas-menu__global-navigation-list-item">
        <a href="http://community.netapp.com/" class="n-off-canvas-menu__global-navigation-link">Community</a>
      </li>

      <li class="n-off-canvas-menu__global-navigation-list-item">
        <a href="http://www.netapp.com/us/services-support/university/index.aspx"
          class="n-off-canvas-menu__global-navigation-link">Training</a>
      </li>

      <li class="n-off-canvas-menu__global-navigation-list-item">
        <a href="http://www.netapp.com/us/services-support/ngs-contacts.aspx"
          class="n-off-canvas-menu__global-navigation-link">Contact</a>
      </li>
    </ul>
  </div>
</div>