<div style="padding-top: 10px; clear: both;">
  <input #uploadFileInput type="file" class="custom-file-input" style="display: none;"
  (change)="whenFileSelect($event)" required />
</div>
<div style="margin-top: 20px;">
  <span class="upload__label">{{'sfu_upload_files'| translate}}</span>
  <button class="button button--small" id="upload_button"
    (click)="uploadFileInput.click()"
    [disabled]="isDisabled"
    style="text-transform: none;">{{ 'sfu_upload_button_text' | translate }}</button>
</div>
<label *ngIf="fileName" for="userfile" class="n-form__label file-name">
  <span style="color: #0067c5;">{{ 'sfu_file_name_label_text'| translate }}</span>
  <small>{{ fileName }}</small>
</label>
<div *ngIf="errorMsg" class="alert alert-danger">
  <strong>Error:</strong> {{ errorMsg | translate}}
</div>