<div class="n-off-canvas-menu">
  <div class="n-off-canvas-menu__content-wrap">
    <main role="main" id="n-main-content" class="n-main-content">
      <div class="n-container">
        <div class="content_holder">
          <!-- <div>
            <h4 class="n-auth-file-upload">{{ 'sfu_upload_detail_title' | translate }}</h4>
          </div> -->
          <div class="n-col-lg-8">
            <app-filter-panel (whenFilter)="filterData($event)" [fileTypes]="filterFileTypes"></app-filter-panel>
          </div>
          <div class="n-col-lg-4" style="text-align: right;">
            <app-pagination-panel
              (whenPaginate)="applyPagination($event)"
              [dataCount]="filteredRowData.length">
            </app-pagination-panel>
          </div>
          <div class="n-row">
            <div class="n-col-lg-12" style="overflow: hidden;">
              <app-summary-grid
                [columnDefs]="columnDefs"
                [errorMsg]="errorMsg"
                [isLoading]="loading"
                [rowData]="displayRowData">
              </app-summary-grid>
              <!-- <div class="detail-grid-size-drop-down">
                <span class="pdr-5">{{'sfu_page_size' | translate}}</span>
                <select>
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
            </div> -->
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>