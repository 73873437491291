<div class="pagination-block">
  Show:
  <select name="listPerPage" [formControl]="pageSizeCtrl" class="page-sizes">
    <option *ngFor="let val of [10, 25, 50, 100]"[value]="val">
      {{ val }}
    </option>
  </select>
  <span (click)="navigatePage(-1)" class="prev-icon"> < </span>
  <span>
    <span>
      Page {{ currentPage }}
    </span>
    of {{ totalPage }}
  </span>
  <span (click)="navigatePage(1)" class="next-icon"> > </span>
</div>