import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { REFRESH_COUNT } from './constants';
import { AuthService } from './services/auth/auth.service';
import { TokenService } from './services/token/token.service';
import { EnvService } from './services/env/env.service';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from './services/session/session.service';
import { LoginInfo } from './shared/models';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  private $routeSubscription: Subscription | null = null
  userVerified = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private token: TokenService,
    private session: SessionService,
    private translate: TranslateService,
    private env: EnvService) {

    this.translate.setDefaultLang('en');
    this.translate.use('en');
    
  }

  ngOnInit() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.doAuthentication();
    });
  }

  private doAuthentication() {
    if (this.$routeSubscription) {
      return
    }
    this.$routeSubscription = this.route.queryParams.subscribe((params: any) => {
      const token = this.token.getAccessToken();
      if (params.code) {
        this.generateToken(params.code);
      } else if (token) {
      this.getRbacRes(token);
      } else {
        this.session.setItem('url', window.location.href);
        this.auth.login();
      }
    });
  }

  private generateToken(code: string) {
    this.token.generate(code).subscribe({
      next: token => {
        if (token) {
          this.refreshPage()
        } else {
          this.showError();
        }
      },
      error: () => {
        this.token.invalidate()
        this.showError();
      }
    })
  }

  private refreshPage(): void {
    this.router.navigate(["."], {
      queryParams: {},
    });
  }

  /**
   * Reload current page without query param if user has access, or redirect to NAFU page to upload
   * */
  private validateUser(info: LoginInfo, isUploadRestricted: any,isTransDataRestricted:boolean,isSecureUser:boolean) {
    let isInternal = info['isinternal'] === "true" ? true : false;
    if (this.auth.validate(info, this.router.url)) {
      if (this.router.url.includes('/transData')) {
      //navigating to transData page on login
      
       if(!isInternal){
        this.router.navigate(['/']);
        }else if(isInternal && isTransDataRestricted) {
          this.router.navigate(['/accessDenied']);
        }else{
          this.router.navigate(['/transData'], {
            queryParams: {},
          });
        }
      }
      this.session.removeItem('url');
    } else {
      if(isInternal && isSecureUser && isUploadRestricted) {
        this.router.navigate(['/accessDenied']);
      }else{
      this.session.setItem(REFRESH_COUNT, '0');
      let url= `${this.env.environment.nafuURL}sg`
      let errMsg = `${this.translate.instant('sfu_no_secured_user_msg')} ${url}. ${this.translate.instant('sfu_no_secured_user_msg1')}`
      alert(this.translate.instant(errMsg));
      this.token.invalidate();
      this.auth.logout(url);
      }
    }
  }

  showError(): void {
    alert(this.translate.instant('sfu_token_generation_failed'))
    this.token.invalidate()
    let url= this.env.environment.redirectUri
    this.auth.logout(url)
  }

  ngOnDestroy(): void {
    this.$routeSubscription?.unsubscribe()
  }
  getRbacRes(token: string) {
    this.token.getRbacRes(token).subscribe({
      next: response => {
        this.session.setItem('isTransDataRestricted', response.isTransDataRestricted);
        const info = this.auth.notifyLogin(token, response.isAuthorized)
        this.validateUser(info, response.isUploadRestricted,response.isTransDataRestricted,response.isSecureUser);
      },
      error: () => {
        this.token.invalidate()
        let url= this.env.environment.redirectUri
        this.auth.logout(url)
      }
    })
  }
}
