import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css'],
})
export class ProgressBarComponent {

  @Input()
  percentage = 0;

  @Input()
  allowInterruptions = ['pause', 'cancel']

  @Output()
  whenInterrupt = new EventEmitter<'pause' | 'resume' | 'cancel'>();

  paused = false
  cancelled = false

  get completed(): boolean {
    return this.percentage === 100;
  }

  progressText(): string {
    let text = 'sfu_uploading'
    if (this.paused) {
      text = 'sfu_paused'
    } 
    if (this.cancelled) {
      text = 'sfu_cancelling'
    }
    return text;
  }

  pauseProgress(pause: boolean): void {
    this.paused = pause
    this.whenInterrupt.emit(pause ? 'pause' : 'resume')
  }

  cancelProgress(): void {
    this.cancelled = true
    this.whenInterrupt.emit('cancel')
  }

  showInterruption(key: string): boolean {
    return this.allowInterruptions.includes(key)
  }

}
