import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-summary-grid',
  templateUrl: './summary-grid.component.html',
  styleUrls: ['./summary-grid.component.css']
})
export class SummaryGridComponent {
  @Input()
  columnDefs: any[];

  @Input()
  rowData: any[]

  @Input()
  errorMsg = ''

  @Input()
  isLoading = true

  constructor() {
    this.rowData = [];
    this.columnDefs = []
  }

  getMessage(): string {
    if (this.errorMsg) {
      return this.errorMsg || ''

    } else if (!this.isLoading && !this.rowData.length) {
      return 'sfu_no_data_found'
    }
    return ''
  }

}
