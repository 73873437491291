import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

enum PlatformType {
  PROD = 'prod',
  DEV = 'dev',
  STAGE = 'stage'
}

@Injectable({
  providedIn: 'root'
})
export class EnvService {

  getPlatform(): PlatformType {
    let platform = PlatformType.DEV;
    const currentURL = window.location.href;

    if (currentURL.includes('uploadprotect.netapp.com')) {
      platform = PlatformType.PROD
    } else if (currentURL.includes('uploadsesg-stage.netapp.com')) {
      platform = PlatformType.STAGE
    }
    return platform
  }

  get environment(): any {
    return environment[this.getPlatform()]
  }
}
