<!-- <div class="page-loading" *ngIf="!isLoggedIn">
  <div [ngClass]="{ 'sfu-hide': isLoggedIn }" class="sfu-loading">
    Loading...
  </div>
</div> -->
<div class="n-off-canvas-menu">
  <!-- <app-off-canvas></app-off-canvas> -->
  <div class="n-off-canvas-menu__content-wrap">
    <!-- <app-header-secure ></app-header-secure> -->
    <main role="main" class="n-main-content" id="n-main-content">
      <div class="n-container">
        <div class="content_holder">
          <div class="n-row" style="font-size: 14px;">
            <div style="text-align: justify; margin-bottom: 40vh;">
              <b>Access Denied</b><br />
              You do not have access. Please click on the
              <a
                href="https://netapp.service-now.com/esp?id=sc_cat_item&sys_id=b0d4a4e11b577010b65e968f034bcbfe"
                target="_blank"
                >link</a
              >
              to request access.
              <br />
              <ul style="font-size: 12px;">
                <li>
                  Fill out the form, select:
                </li>
                <li>Checkbox: "NetApp Authenticated File Upload (NAFU)"</li>
                <li>
                  When selected populate the "What is your job?" role with options only:
                </li>
                <li style="margin-left: 3em;">
                  NetApp Authenticated File Upload Support Edge Secure Government (NAFU SESG) upload
                </li>
                <li style="margin-left: 3em;">
                  NetApp Authenticated File Upload Support Edge Secure Government (NAFU SESG) Transdata - Note: select this Only if you need to view the NAFU SESG Transdata page.
                </li>
                <li>
                  Select your job
                </li>
                <li>
                  Comment* - fill in your job role and 'Business Justification for this access request'.
                </li>
                <li>
                  Press submit.
                </li>
                <li>
                  Your request will be sent to for approval.
                </li>
                <li>
                  Once your access request is approved, access will be granted immediately.
                </li>
              </ul>
              If you run into production issue, please submit a helpdesk ticket and assigned to <b>Engineering > Active IQ > RSA</b>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>

  <!-- <app-footer></app-footer> -->
</div>
