import { Injectable } from '@angular/core';
import { Observable, interval, map } from 'rxjs';

export interface Time {
  hours: string;
  minutes: string;
  seconds: string;
}

@Injectable()
export class SessionService {

  secs = 0;

  removeItem(key: string) {
    localStorage.removeItem(key)
  }
  setItem(key: string, value: string | null) {
    localStorage.setItem(key, String(value));
  }
  getItem(key: string): string | null {
    return localStorage.getItem(key);
  }
  set loggedIn(value: any) {
    localStorage.setItem('is_loggedIn', value);
  }
  get loggedIn() {
    return localStorage.getItem('is_loggedIn');
  }

  timeCounter(reset: boolean): Observable<Time> {
    if (reset) { this.secs = 0; }
    return interval(600000).pipe(map(() => this.createTimeObject()))
  }

  private createTimeObject(): Time {
    this.secs++;
    const hours = Math.floor(this.secs / 3600);
    const minutes = Math.floor((this.secs - (hours * 3600)) / 60);
    let seconds = this.secs - (hours * 3600) - (minutes * 60);
    seconds = Math.round(seconds * 100) / 100
    const time: Time = { hours: '0', minutes: '0', seconds: '0' };
    time.hours = (hours < 10 ? '0' + hours : '' + hours);
    time.minutes = (minutes < 10 ? '0' + minutes : '' + minutes)
    time.seconds = (seconds < 10 ? '0' + seconds : '' + seconds);
    return time;
  }

}
