const STAGE_HOST = 'https://uploadsesg-stage.netapp.com'
const PROD_HOST = 'https://uploadprotect.netapp.com'
export const environment = {
  dev: {
    oauthLoginUrl:
      "https://ntapb2c.b2clogin.com/ntapb2c.onmicrosoft.com/B2C_1A_TEST_NAFUSESG_OAUTH_STG/oauth2/v2.0/authorize"
      + "?response_type=code&client_id=777d1276-34d7-4042-8b4a-f5487cfa932e&scope=https://ntapb2c.onmicrosoft.com/NAFUSESG.ntapb2c.com/UserAPI&redirect_uri=",
    oauthLogoutUrl:
      "https://ntapb2c.b2clogin.com/ntapb2c.onmicrosoft.com/B2C_1A_TEST_NAFUSESG_OAUTH_STG/oauth2/v2.0/logout?post_logout_redirect_uri=",
    redirectUri: "http://localhost:4200",
    nafuURL: "https://upload-stage.netapp.com/",
    generateTokenUrl: "http://localhost:8080/api/secure/generateToken",
    refreshTokenUrl: "http://localhost:8080/api/secure/refreshToken",
    getPreviousUploadDetails: "http://localhost:8080/api/secure/multipart/getPreviousUploadDetails",
    getSasUrl: "http://localhost:8080/api/secure/singleFileUpload/getPresignedURL",
    getSasUrls: "http://localhost:8080/api/secure/multipart/getPresignURLs",
    completeMultipartUploadUrl: "http://localhost:8080/api/secure/multipart/complete",
    cancelMultipartUploadUrl: "http://localhost:8080/api/secure/multipart/cancel",
    getLogsUrl: "http://localhost:8080/api/secure/db/uploadDetails",
    loadCaseDetailUrl: "http://localhost:8080/api/secure/getCaseDetails",
    updateLogsUrl: "http://localhost:8080/api/secure/db/update",
    setActiveSessionUrl: "http://localhost:8080/api/secure/setActiveSessionDate",
    rbacUrl:"http://localhost:8080/api/secure/rbac",
    loggingsUrl: "http://localhost:8080/api/secure/uploadLogs"
  },
  stage: {
    oauthLoginUrl:
      "https://ntapb2c.b2clogin.com/ntapb2c.onmicrosoft.com/B2C_1A_TEST_NAFUSESG_OAUTH_STG/oauth2/v2.0/authorize"
      + "?response_type=code&client_id=777d1276-34d7-4042-8b4a-f5487cfa932e&scope=https://ntapb2c.onmicrosoft.com/NAFUSESG.ntapb2c.com/UserAPI&redirect_uri=",
    oauthLogoutUrl:
      "https://ntapb2c.b2clogin.com/ntapb2c.onmicrosoft.com/B2C_1A_TEST_NAFUSESG_OAUTH_STG/oauth2/v2.0/logout?post_logout_redirect_uri=",
      redirectUri: STAGE_HOST,
      nafuURL: "https://upload-stage.netapp.com/",
      generateTokenUrl: STAGE_HOST + "/api/secure/generateToken",
      refreshTokenUrl: STAGE_HOST + "/api/secure/refreshToken",
      getPreviousUploadDetails: STAGE_HOST +"/api/secure/multipart/getPreviousUploadDetails",
      getSasUrl: STAGE_HOST + "/api/secure/singleFileUpload/getPresignedURL",
      getSasUrls: STAGE_HOST + "/api/secure/multipart/getPresignURLs",
      completeMultipartUploadUrl: STAGE_HOST + "/api/secure/multipart/complete",
      cancelMultipartUploadUrl: STAGE_HOST + "/api/secure/multipart/cancel",
      getLogsUrl: STAGE_HOST + "/api/secure/db/uploadDetails",
      loadCaseDetailUrl: STAGE_HOST + "/api/secure/getCaseDetails",
      updateLogsUrl: STAGE_HOST + "/api/secure/db/update",
      setActiveSessionUrl: STAGE_HOST + "/api/secure/setActiveSessionDate",
      rbacUrl: STAGE_HOST + "/api/secure/rbac",
      loggingsUrl: STAGE_HOST + "/api/secure/uploadLogs"
  },
  prod: {
    oauthLoginUrl:
      "https://signin.b2c.netapp.com/b2773257-321f-4723-b48b-224b36d0212a/B2C_1A_NAFU_SESG_PROD/oauth2/v2.0/authorize"
      + "?response_type=code&client_id=4c37abf1-9004-4405-942f-f9439a024b77&scope=https://ntapb2c.onmicrosoft.com/NAFUSESG.ntapb2c.com/UserAPI&redirect_uri=",
    oauthLogoutUrl:
      "https://signin.b2c.netapp.com/b2773257-321f-4723-b48b-224b36d0212a/B2C_1A_NAFU_SESG_PROD/oauth2/v2.0/logout?post_logout_redirect_uri=",
      redirectUri: PROD_HOST,
      nafuURL: "https://upload.netapp.com/",
      generateTokenUrl: PROD_HOST + "/api/secure/generateToken",
      refreshTokenUrl: PROD_HOST + "/api/secure/refreshToken",
      getPreviousUploadDetails: PROD_HOST +"/api/secure/multipart/getPreviousUploadDetails",
      getSasUrl: PROD_HOST + "/api/secure/singleFileUpload/getPresignedURL",
      getSasUrls: PROD_HOST + "/api/secure/multipart/getPresignURLs",
      completeMultipartUploadUrl: PROD_HOST + "/api/secure/multipart/complete",
      cancelMultipartUploadUrl: PROD_HOST + "/api/secure/multipart/cancel",
      getLogsUrl: PROD_HOST + "/api/secure/db/uploadDetails",
      loadCaseDetailUrl: PROD_HOST + "/api/secure/getCaseDetails",
      updateLogsUrl: PROD_HOST + "/api/secure/db/update",
      setActiveSessionUrl:  PROD_HOST + "/api/secure/setActiveSessionDate",
      rbacUrl: PROD_HOST + "/api/secure/rbac",
      loggingsUrl: PROD_HOST + "/api/secure/uploadLogs"
  }
};
