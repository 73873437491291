import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as jose from 'jose';
import { ReplaySubject } from 'rxjs';
import { LoginInfo } from 'src/app/shared/models';
import { EnvService } from '../env/env.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public $loginInfo = new ReplaySubject<LoginInfo>(1)

  constructor(private http: HttpClient, private env: EnvService) { }

  login(): void {
    window.open(this.env.environment.oauthLoginUrl + this.env.environment.redirectUri, "_self");
  }

  logout(url:string): void {
    window.open(this.env.environment.oauthLogoutUrl + url, "_self");
  }

  notifyLogin(token: string,rbacRes:boolean): LoginInfo {
    const info: LoginInfo = jose.decodeJwt(token)
    info.allowFileUpload = rbacRes === true
    if (!info.uid) {
      info.uid = info['ntapUID'] + ''
    }
    this.$loginInfo.next(info)
    return info
  }

  validate(info: LoginInfo, url: string): boolean {
    if (url.includes('/transData')) {
      return true  // Validation to be added
    } else {
      return !!info.allowFileUpload
    }
  }
}
