<div class="filter-container">
  <div class="date-filter-wrap">
    <strong class="pdr-5">{{"sfu_start_date" | translate}}</strong>
    <input
      type="date"
      name="startDate"
      [formControl]="startDateCtrl"
    />
    <strong class="pdr-5" style="padding-left: 20px;"> {{"sfu_end_date" | translate}}</strong>
    <input
      type="date"
      name="endDate"
      [formControl]="endDateCtrl"
      style="margin-right: 5px;"
    />
    <span
      *ngIf="invalidStartEndDate"
      class="validation-error"
      >{{"sfu_date_validation_error" | translate}}</span
    >
  </div>
  <div>
    <label>
      <strong>Case#:</strong>
      <input
        type="text"
        name="caseNumber"
        [formControl]="caseNumberCtrl"
      />
    </label>
    <label>
      <strong>File Name:</strong>
      <input type="text" name="fileName" [formControl]="fileNameCtrl"/>
    </label>
    <!-- <label>
      <strong>File Type:</strong>
      <select
        name="fileType"
        style="height: 25px;"
        [formControl]="fileTypeCtrl"
      >
        <option value="">All</option>
        <option *ngFor="let ft of fileTypes" [value]="ft">{{ ft }}</option>
      </select>
    </label> -->
    <button class="button button--small" (click)="filter()">
      {{ 'sfu_search' | translate }}
    </button>
  </div>
</div>