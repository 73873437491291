<div class="page-loading" *ngIf="!userVerified">
  <div class="sfu-loading">
    Loading...
  </div>
</div>
<div class="n-off-canvas-menu" *ngIf="userVerified">
  <div class="n-off-canvas-menu__content-wrap">
    <main role="main" id="n-main-content" class="n-main-content">
      <div class="n-container">
        <div class="content_holder">
          <div class="n-row">
            <div class="n-col-lg-7">
              <h4 class="n-auth-file-upload">{{ 'sfu_page_heading' | translate }}</h4>
              <br />
              <div class="n-form__field-group">
                <app-case-number (afterValidate)="whenCasenumberSelect($event)"></app-case-number>
                <app-wait-spinner *ngIf="spinner"></app-wait-spinner>
                <app-select-file (afterValidate)="whenFileSelect($event)" [isDisabled]="disableFileSelection"></app-select-file>
              </div>
              <div class="radio-box" *ngIf="showRadioButton">
                <div class="radio-item">
                  <input
                    type="radio"
                    id="resume"
                    name="radioValue"
                    value="resume"
                    (change)="radioHandlerEvent($event)"
                  />
                  <label for="resume"><strong>Resume</strong></label>
                  <label for="resume" class="radio-check"> </label>
                </div>
                <div class="radio-item">
                  <input
                    type="radio"
                    id="new"
                    name="radioValue"
                    value="fresh_upload"
                    (change)="radioHandlerEvent($event)"
                  />
                  <label for="new"><strong>New Upload</strong></label>
                  <label for="new" class="radio-check"> </label>
                </div>
                <!-- <input type="radio" name="radioValue" value="resume" (change)="radioHandlerEvent($event)">Resume 
                <input type="radio" name="radioValue" value="fresh_upload" (change)="radioHandlerEvent($event)">Start a Fresh Upload -->
              </div>

              <div *ngIf="isUploading" class="upload-progress-block" #uploadProgressBlock>
                <div class="progress-block">
                  <app-progress-bar
                    style="float: left; margin-top: 13px;"
                    [percentage]="uploadProgress"
                    [allowInterruptions]="isSingleUpload() ? [] : ['pause', 'cancel']"
                    (whenInterrupt)="interruptUpload($event)"
                  ></app-progress-bar>
                </div>
              </div>
              <!-- <app-upload-status-message [uploadStatus]="uploadStatus"></app-upload-status-message> -->
              <app-upload-status-message [uploadStatus]="uploadStatus" [uploadMessage]="uploadMessage"></app-upload-status-message>
            </div>
            <div class="n-col-lg-5 effect8 mbl-footer-height" style="padding-top: 2px; width: 385px;">
              <app-upload-instruction></app-upload-instruction>
            </div>
            <div class="n-col-lg-5" style="padding-top: 2px; margin-top:10px; width: 385px;">
              <label for="note">Note: </label>
              <span id="sfu_file_instruction_note" [innerHtml]="'sfu_file_instruction_note' | translate | safeHtml">
              </span>
            </div>
          
          </div>
        </div>
      </div>
    </main>
  </div>
</div>
