import { HttpEvent, HttpEventType } from "@angular/common/http";
import {
  Component, OnDestroy, OnInit
} from "@angular/core";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/services/auth/auth.service";
import { UploadService } from "src/app/services/upload/upload.service";
import { UtilsService } from "src/app/services/utils/utils.service";
import { LoginInfo, MultipartUploadProgress, PreSignedUrl, PreSignedUrlModel, SelectedFile, UploadParam, UploadSummaryStatus, multipartMap } from "src/app/shared/models";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit, OnDestroy {
  userVerified = false;
  spinner = false;
  $subscriptions: Subscription[]
  private caseNumber = ''
  isUploading = false
  uploadProgress = 0
  uploadStatus: 'success' | 'failed' | 'presignedUrl_error' | 'cancelled' | null = null
  uploadMessage = ''

  loginInfo: LoginInfo | null = null

  multipartUploadModel: MultipartUploadProgress | null
  singleUploadModel: PreSignedUrl | null = null
  payload: UploadParam | null = null
  private $uploadSubscription: Subscription | null = null
  uploadId: string | null = null
  prevUploadData: any;
  showRadioButton: boolean = false;
  isResumeInitiated: boolean = false;
  radioButtonValue: any;
  uploadProgressBlockElement: any;
  progressMap: any = {};
  batchSize = 5;
  batchPrtnumlist: any[] = [];
  private userId:string = '';

  constructor(private auth: AuthService, private upload: UploadService, private util: UtilsService) {
    this.$subscriptions = []
    this.multipartUploadModel = null
  }

  ngOnInit() {
    this.$subscriptions.push(
      this.auth.$loginInfo.subscribe({
        next: (user: LoginInfo) => {
          this.loginInfo = user
          this.userVerified = !!user?.allowFileUpload
        }
      })
    )
  }

  get disableFileSelection(): boolean {
    return !this.caseNumber
  }

  whenCasenumberSelect(caseNumber: string): void {
    this.caseNumber = caseNumber
    this.uploadStatus = null;
  }

  whenFileSelect(file: SelectedFile): void {
    this.uploadStatus = null;
    this.uploadProgress = 0;
    if (!file?.fileName) {
      return
    }
    this.payload = {
      caseNumber: this.caseNumber,
      fileDetails: file,
      loginInfo: this.loginInfo
    }
     this.userId = this.loginInfo?.uid || '';
     if(file.hasMultiparts){
      //get previous upload details for multipart upload based it initial upload or resume upload
      this.getPrevUploadDetails(this.payload);
      this.upload.loggingService({ 
        userName: this.userId, 
        caseNumber: this.caseNumber, 
        fileName: file.fileName, 
        fileSize:file.content.size, 
        Message: "Multipart file upload initiated", 
        type: "info"
      });
    }else{
    // single file upload
    this.upload.loggingService({ 
      userName: this.userId, 
      caseNumber: this.caseNumber, 
      fileName: file.fileName, 
      fileSize:file.content.size , 
      Message: "Single file upload initiated" , 
      type: "info"
    });
    this.upload.getSasUrls(this.payload).subscribe({
      next: (sas: PreSignedUrlModel) => {
        this.upload.loggingService({ 
          userName: this.userId, 
          caseNumber: this.caseNumber, 
          fileName: file.fileName, 
          maskedFileName:sas.preSignedUrls[0].maskedFileName, 
          Message: "Successfully Received Pre-signed url for single file upload", 
          type: "info"
        });
          this.singleUploadModel = sas.preSignedUrls[0]
          this.uploadSingleFile()
      },
      error: () => {
        this.uploadStatus = 'presignedUrl_error';
        this.upload.$uploadStatus.next({ type: 'presigned_url_error' })
        this.upload.loggingService({ 
          userName: this.userId, 
          caseNumber: this.caseNumber, 
          fileName: file.fileName, 
          fileSize:file.content.size, 
          Message: "Error while getting Pre-signed url for single file upload", 
          type: "error"
        });
      }
    })
  }
  }

  private uploadMultipartsFile() {
    this.singleUploadModel = null;

    if (!this.payload || !this.multipartUploadModel) {
      return
    }
    this.upload.loggingService({ 
      userName: this.userId, 
      caseNumber: this.caseNumber, 
      fileName: this.payload.fileDetails.fileName, 
      maskedFileName:this.multipartUploadModel.multipartSas.maskedFileName,  
      Message: "Entering into uploadMultipartsFile() function", 
      type: "info"
    });
    let poolingReq = {
      unique_id: this.multipartUploadModel.multipartSas.unique_id,
      masked_file_name: this.multipartUploadModel.multipartSas.maskedFileName,
      percentage_done: this.uploadProgress,
    }
    this.multipartUploadModel.paused = false;
    this.upload.poolingService(poolingReq,this.multipartUploadModel).subscribe((data) => {
      let poolingRes = data;
    });
    this.isUploading = true;
    this.$uploadSubscription = this.upload.uploadMultipartsFile(this.multipartUploadModel, this.payload).subscribe({
      next: () => {
        let percentage = Math.round(
          ((this.multipartUploadModel?.successCount || 0) /
            (this.multipartUploadModel?.totalCount || 1)) *
            100
        );
        this.uploadProgress = Math.min(percentage, 100);
      },
      complete: () => {
        if (this.multipartUploadModel?.allSucceeded()) {
          this.markSuccess(this.multipartUploadModel)
          this.upload.loggingService({ 
            userName: this.userId, 
            caseNumber: this.caseNumber, 
            fileName: this.payload!.fileDetails.fileName, 
            maskedFileName:this.multipartUploadModel.multipartSas.maskedFileName,  
            Message: "Successfully completed multipart file upload", 
            type: "info"
          });
        } else {
          this.isUploading = false;
          this.uploadStatus = 'failed';
          this.uploadMessage = `${this.payload?.fileDetails.fileName} upload failed.`;
          this.upload.$uploadStatus.next({ type: 'upload_failed' })
          this.upload.loggingService({ 
            userName: this.userId, 
            caseNumber: this.caseNumber, 
            fileName: this.payload!.fileDetails.fileName,  
            Message: "Failed to completed multipart file upload", 
            type: "error"
          });
        }
      }
    })
  }

  private markSuccess(sas: MultipartUploadProgress) {
    const param = {
      fileName: this.payload?.fileDetails.fileName,
      caseNumber: this.payload?.caseNumber,
      fileSize: this.payload?.fileDetails.content.size,
      multipartMap: {Parts:sas.uploadedList},
      uploadId:sas.multipartSas.uploadId,
      maskedFileName:sas.multipartSas.maskedFileName
    }
    this.upload.markMultipartUploadCompleted(param).subscribe({
      next: () => {
        this.isUploading = false;
        this.uploadProgress = 100
        this.uploadStatus = 'success';
        this.uploadMessage = `${this.payload?.fileDetails.fileName} uploaded successfully.`;
        this.caseNumber = '';
        this.upload.$uploadStatus.next({ type: 'upload_success' })
        if (this.payload && this.multipartUploadModel) {
          let poolingReq = {
            unique_id: this.multipartUploadModel.multipartSas.unique_id,
            masked_file_name: this.multipartUploadModel.multipartSas.maskedFileName,
            percentage_done: this.uploadProgress,
          }
          
          this.upload.poolingService(poolingReq,this.multipartUploadModel).subscribe((data) => {
            let poolingRes = data;  
          });
          
          this.upload.updateMultipartSummary(this.payload, this.multipartUploadModel, UploadSummaryStatus.COMPLETED);
        }
      },
      error: () => {
        this.isUploading = false;
        this.uploadStatus = 'failed';
        this.uploadMessage = `${this.payload?.fileDetails.fileName} upload failed.`;
        this.upload.$uploadStatus.next({ type: 'upload_failed' })
        if (this.payload && this.multipartUploadModel) {
          this.upload.updateMultipartSummary(this.payload, this.multipartUploadModel, UploadSummaryStatus.FAILED);
        }
      }
    })
  }

  private uploadSingleFile() {
    this.multipartUploadModel = null

    if (!this.payload || !this.singleUploadModel) {
      return
    }
    const startTime = this.util.today()
    this.upload.loggingService({
      userName: this.userId, 
      caseNumber: this.caseNumber, 
      fileName: this.payload!.fileDetails.fileName, 
      maskedFileName:this.singleUploadModel.maskedFileName,  
      Message: "Single file upload is initiated", 
      type: "info"
    });
    this.upload.updateSingleUploadSummary(this.payload, this.singleUploadModel, startTime)

    this.$uploadSubscription = this.upload.uploadFile(this.singleUploadModel.preSignedUrl, this.payload).subscribe({
      next: (e: HttpEvent<any>) => {
        switch (e.type) {
          case HttpEventType.Sent:
            this.isUploading = true;
            this.uploadProgress = 0;
            break;
            case HttpEventType.UploadProgress:
              let percentage = Math.round((e.loaded / (e.total || 1)) * 100);
              this.uploadProgress = Math.min(percentage, 100);
              break;
          case HttpEventType.Response:
            this.isUploading = false;
            this.uploadProgress = 0;
            this.uploadStatus = 'success';
            this.uploadMessage = `${this.payload?.fileDetails.fileName} uploaded successfully.`;
            this.caseNumber = '';
            this.upload.$uploadStatus.next({ type: 'upload_success' })
            this.upload.updateSingleUploadSummary(this.payload, this.singleUploadModel, startTime, UploadSummaryStatus.COMPLETED)
            this.upload.loggingService({
              userName: this.userId, 
              caseNumber: this.caseNumber, 
              fileName: this.payload?.fileDetails.fileName, 
              maskedFileName:this.singleUploadModel!.maskedFileName,  
              Message: "File uploaded successfully", 
              type: "info"
            });
        }
      },
      error: () => {
        this.uploadStatus = 'failed'
        this.uploadMessage = `${this.payload?.fileDetails.fileName} upload failed.`;
        this.isUploading = false;
        this.uploadProgress = 0;
        this.upload.$uploadStatus.next({ type: 'upload_failed' })
        this.upload.updateSingleUploadSummary(this.payload, this.singleUploadModel, startTime, UploadSummaryStatus.FAILED)
        this.upload.loggingService({
          userName: this.userId, 
          caseNumber: this.caseNumber, 
          fileName: this.payload!.fileDetails.fileName, 
          maskedFileName:this.singleUploadModel!.maskedFileName,  
          Message: "single file upload failed" , 
          type: "error"
        });
      }
    })
  }

  interruptUpload(type: string): void {
    if (type === 'cancel') {
      this.upload.updateMultipartSummary(
        this.payload,
        this.multipartUploadModel,
        UploadSummaryStatus.CANCELLED
      );
      this.cancelUpload();

    } else if (type === 'pause') {
      this.upload.updateMultipartSummary(
        this.payload,
        this.multipartUploadModel,
        UploadSummaryStatus.PAUSED
      );
      this.pauseUpload();
      
    } else if (type === 'resume') {
      this.upload.loggingService({
        userName: this.userId, 
        caseNumber: this.caseNumber, 
        fileName: this.payload!.fileDetails.fileName, 
        maskedFileName:this.multipartUploadModel!.multipartSas.maskedFileName,  
        Message: " Multipart file upload is resumed.", 
        type: "info"
      });
      this.resumeUpload();
    }
  }

  private cancelUpload(): void {
    this.$uploadSubscription?.unsubscribe();

    if (this.multipartUploadModel !== null) {
      this.multipartUploadModel?.resetProgress();
      const param = {
        fileName: this.payload?.fileDetails.fileName,
        fileSize: this.payload?.fileDetails.content.size,
        caseNumber: this.payload?.caseNumber,
        uploadId: this.multipartUploadModel.multipartSas.uploadId,
        maskedFileName: this.multipartUploadModel.multipartSas.maskedFileName,
      }
      this.upload.cancelMultipartsUpload(param).subscribe({
        next: () => {
          this.multipartUploadModel = null;
          this.uploadStatus = 'cancelled'
          this.uploadMessage = `${this.payload?.fileDetails.fileName} upload cancelled.`;
          this.isUploading = false
          this.upload.$uploadStatus.next({ type: 'upload_cancelled' })
          this.upload.loggingService({
            userName: this.userId, 
            caseNumber: this.caseNumber, 
            fileName: this.payload!.fileDetails.fileName, 
            maskedFileName:this.multipartUploadModel!.multipartSas.maskedFileName,  
            Message: "Multipart file upload is cancelled successfully", 
            type: "info"
          });
          
        },
        error: () => {
          this.multipartUploadModel = null;
          this.upload.loggingService({
            userName: this.userId, 
            caseNumber: this.caseNumber, 
            fileName: this.payload!.fileDetails.fileName, 
            maskedFileName:this.multipartUploadModel!.multipartSas.maskedFileName,  
            Message: "Multipart file upload is cancelled failed", 
            type: "error"});
        }
      })
    }
  }

  private pauseUpload(): void {
    this.$uploadSubscription?.unsubscribe();
    this.multipartUploadModel?.resetProgress(true);
    this.upload.loggingService({
      userName: this.userId, 
      caseNumber: this.caseNumber, 
      fileName: this.payload!.fileDetails.fileName, 
      maskedFileName:this.multipartUploadModel!.multipartSas.maskedFileName,  
      Message: " Multipart file upload is paused.", 
      type: "info"
    });
  }

  private resumeUpload(): void {
    this.upload.loggingService({
      userName: this.userId, 
      caseNumber: this.caseNumber, 
      fileName: this.payload!.fileDetails.fileName,
      maskedFileName:this.multipartUploadModel!.multipartSas.maskedFileName,  
      Message: "Multipart file upload is resumed.", 
      type: "info"
    });
    this.uploadMultipartsFile()
  }

  isSingleUpload(): boolean {
    return !this.payload?.fileDetails.hasMultiparts
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => s.unsubscribe())
  }
// get previous upload details for multipart upload
  private getPrevUploadDetails(param: UploadParam) {
    this.upload.getPrevFileUploadDetails(param).subscribe({
      next: (res) => {
        this.upload.loggingService({
          userName: this.userId, 
          caseNumber: this.caseNumber, 
          fileName: param.fileDetails.fileName,  
          Message: "successfully received previous upload details for multipart file upload", 
          type: "info"
        });
        if (res) {
          this.prevUploadData = res;
          if (this.prevUploadData.PartialUpload === 'Y') {
            this.showRadioButton = true;
          } else {
            this.showRadioButton = false;
            this.getBatchMutltipartPresignedUrls(param);
          }
        }
      },
      error: () => {
        this.showRadioButton = false;
        this.upload.loggingService({
          userName: this.userId, 
          caseNumber: this.caseNumber, 
          fileName: param.fileDetails.fileName,  
          Message: "Error while getting previous upload details for multipart file upload", 
          type: "error"
        });
      },
    });
  }

  //resume functionality on reload or refresh or log off
  private initiateResumeUpload(param: UploadParam) {
    if (this.isResumeInitiated && this.payload !== null) {
      this.upload.loggingService({
        userName: this.userId, 
        caseNumber: this.caseNumber, 
        fileName: param.fileDetails.fileName,  
        Message: "Resume upload initiated", 
        type: "info"
      });
      // Create a full list of part numbers from 1 to totalParts
      let fullList = Array.from(
        { length: this.prevUploadData.totalParts },
        (_, i) => i + 1
      );
      let partNumberList = this.prevUploadData.Parts.map((part: any) => {
        return part.PartNumber;
      });
      // Find the missing part numbers by filtering the full list
      let newPartNumList = fullList.filter(
        (partNumber) => !partNumberList.includes(partNumber)
      );
      param.uploadId = this.prevUploadData.uploadId;
      param.unique_id = this.prevUploadData.unique_id;
      param.maskedFileName = this.prevUploadData.maskedFileName;
      if (newPartNumList.length > 0) {
        const getpartData = this.util.getPartDetails(param.fileDetails.content.size)
        let batchSize = 2 * getpartData.batchSize;
        const totalParts = getpartData.totalParts;
        const numBatches = Math.ceil(totalParts / batchSize);
        const partNumList: any[] = [];
        for (let i = 0; i < numBatches; i++) {
          let batch = newPartNumList.slice(i * batchSize, (i + 1) * batchSize);
          partNumList.push(batch);
        }
        this.batchPrtnumlist = partNumList;

        if (partNumList.length > 0) {
          param.partNumberList = partNumList[0];

          this.upload.getSasUrls(param).subscribe({
            next: (sas: PreSignedUrlModel) => {
            sas.uploadSummary = this.prevUploadData;
            this.multipartUploadModel = new MultipartUploadProgress(sas, param, partNumList);
            this.upload.loggingService({
              userName: this.userId, 
              caseNumber: this.caseNumber, 
              fileName: param.fileDetails.fileName, 
              maskedFileNam: this.prevUploadData.maskedFileName,  
              Message: "Resume upload initiated", 
              type: "info"
            });
            let percentage = Math.round(
              ((this.multipartUploadModel?.successCount || 0) /
                (this.multipartUploadModel?.totalCount || 1)) *
                100
            );
            this.uploadProgress = Math.min(percentage, 100);
            this.uploadMultipartsFile();
          },
          error: () => {
            this.uploadStatus = 'presignedUrl_error';
            this.upload.$uploadStatus.next({ type: 'presigned_url_error' })
            this.upload.loggingService({
              userName: this.userId, 
              caseNumber: this.caseNumber, 
              fileName: param.fileDetails.fileName, 
              maskedFileNam: this.prevUploadData.maskedFileName,  
              Message: "Error while getting Pre-signed url for resume upload", 
              type: "error"
            });
          }
        })
        }
      }else{
        // if all parts are uploaded but failed to complete
          this.uploadStatus = 'success';
          this.uploadMessage = `${this.payload?.fileDetails.fileName} uploaded successfully.`;
          this.upload.$uploadStatus.next({ type: 'upload_success' });
         let sas: PreSignedUrlModel = {
           totalParts: 0,
           uploadId: "",
           preSignedUrls: []
         }; // Initialize 'sas' variable with an empty object
         
         sas.uploadSummary = this.prevUploadData;
         sas.uploadId = this.prevUploadData.uploadId;
         sas.maskedFileName = this.prevUploadData.maskedFileName;
         sas.totalParts = this.prevUploadData.totalParts;
          this.multipartUploadModel = new MultipartUploadProgress(sas,this.payload,this.batchPrtnumlist);
          let percentage = Math.round(
            ((this.multipartUploadModel?.successCount || 0) /
              (this.multipartUploadModel?.totalCount || 1)) *
              100
          );
          
          this.uploadProgress = Math.min(percentage, 100);
          this.markSuccess(this.multipartUploadModel);
      }
     
    }
  }

  radioHandlerEvent(event: any) {
    this.radioButtonValue = event.target.value;

    if (this.radioButtonValue === 'fresh_upload') {
      this.isResumeInitiated = false;
      if (this.payload) {
        this.getBatchMutltipartPresignedUrls(this.payload);
      }
      this.showRadioButton = false;
    } else {
      this.isResumeInitiated = true;
      this.showRadioButton = false;
      if (this.payload) {
        this.initiateResumeUpload(this.payload);
      }
    }

    if (this.uploadProgressBlockElement) {
      this.uploadProgressBlockElement.nativeElement.style.display = 'block';
    }
  }

  // batch process implementation
  private getBatchMutltipartPresignedUrls(param: UploadParam) {
    this.upload.loggingService({
      userName: this.userId, 
      caseNumber: this.caseNumber, 
      fileName: param.fileDetails.fileName,  
      Message: "Entering into getBatchMutltipartPresignedUrls() function, getting pre-signed url for multipart file upload", 
      type: "info"
    });
    const getpartData = this.util.getPartDetails(param.fileDetails.content.size)
    let batchSize = 2 * getpartData.batchSize;
    const totalParts = getpartData.totalParts;
    const numBatches = Math.ceil(totalParts / batchSize);
    const partNumList: any[] = [];
    for (let i = 0; i < numBatches; i++) {
      const start = i * batchSize + 1;
      const end = Math.min(start + batchSize - 1, totalParts);
      const partList = Array.from(
        { length: end - start + 1 },
        (_, index) => start + index
      );
      partNumList.push(partList);
    }
    this.batchPrtnumlist = partNumList;
    if (partNumList.length > 0) {
      param.partNumberList = partNumList[0];

      this.upload.getSasUrls(param).subscribe({
        next: (sas: PreSignedUrlModel) => {
          this.multipartUploadModel = new MultipartUploadProgress(sas, param, partNumList );
          this.uploadMultipartsFile();
        },
        error: () => {
          this.uploadStatus = 'presignedUrl_error';
          this.upload.$uploadStatus.next({ type: 'presigned_url_error' })
          this.upload.loggingService({
            userName: this.userId, 
            caseNumber: this.caseNumber, 
            fileName: param.fileDetails.fileName,  
            Message: "Error while getting Pre-signed url for multipart file upload", 
            type: "error"
          });
        },
      });
    }
  }
}
