import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, switchMap, throwError } from "rxjs";
import { AUTHORIZATION } from "../constants";
import { AuthService } from "../services/auth/auth.service";
import { TokenService } from "../services/token/token.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private token: TokenService, private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError<any, any>(error => {
        if (error instanceof HttpErrorResponse
          && req.url.includes('/api/secure')
          && error.status === 401
          && req.headers.get(AUTHORIZATION) && !(error.error.message === "You are not authorized to access this case number." || error.error.message === "This is not a secure Case")) {
          return this.handle401Error(req, next);
        }
        return throwError(() => error);
      })
    );
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    return this.token.refresh().pipe(
      switchMap((token: string) => {
        const clonedReq = request.clone({ headers: request.headers.set(AUTHORIZATION, 'Bearer ' + token) });
        return next.handle(clonedReq)
      }),
      catchError((err) => {
        this.token.invalidate()
        setTimeout(() => this.auth.login());
        return throwError(() => err);
      })
    )
  }
}
