import { SINGLE_UPLOAD_SIZE_LIMIT } from "src/app/constants"

function dateFormatter(val: string): string {
  return val
}

export const DETAIL_COL_DEFINITION = [
  {
    headerName: 'File Name',
    field: 'filename',
  },
  {
    headerName: 'Masked File Name',
    field: 'masked_file_name',
  },
  {
    headerName: 'File Size',
    field: 'file_size',
  },
  {
    headerName: 'Upload Status',
    field: 'upload_status',
  },
  {
    headerName: 'Upload Transfer Rate',
    field: 'upload_transfer_rate',
  },
  {
    headerName: 'Case Number',
    field: 'case_number',
  },
  {
    headerName: 'Case Type',
    field: 'case_type',
  },
  {
    headerName: 'Time Zone',
    field: 'time_zone',
  },
  {
    headerName: 'Upload Type',
    field: 'upload_type',
    // valueFormatter: (row: any) => {
    //   return row.file_size < SINGLE_UPLOAD_SIZE_LIMIT ? 'Single upload' : 'Multiparts upload'
    // }
  },
  {
    headerName: 'Upload Start Time',
    field: 'upload_start_time',
    valueFormatter: (row: any) => {
      return dateFormatter(row.upload_start_time)
    }
  },
  {
    headerName: 'Upload End Time',
    field: 'upload_end_time',
    valueFormatter: (row: any) => {
      return dateFormatter(row.upload_end_time)
    }
  },
  {
    headerName: 'Upload Cancel Time',
    field: 'upload_cancel_time',
    valueFormatter: (row: any) => {
      return dateFormatter(row.upload_cancel_time)
    }
  }]
  