import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse, UploadSummary } from 'src/app/shared/models';
import { EnvService } from '../env/env.service';
import {MULTIPART_DYNMC_FILE_SIZE_PROP} from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private env: EnvService) { }

  redirectToNafu(): void {
    let url = `${this.env.environment.nafuURL}sg`
    window.location.assign(url)
  }

  getFilePart(file: File, partIndex: number, totalPartCount: number): Observable<Blob> {
    const chunkSize = Math.ceil(file.size / totalPartCount)
    const start = partIndex * chunkSize
    const end = start + chunkSize

    const reader = new FileReader();
    const slice = file.slice(start, Math.min(end, file.size));
    reader.readAsArrayBuffer(slice);
    return new Observable((observer) => {
      reader.onload = () => {
        const blobObj = new Blob([reader.result || ''], { type: file.type });
        observer.next(blobObj);
        observer.complete()
      };
    });
  }

  parseDateWithoutTime(dateString: string): Date | null {
    // Remove time parts
    return dateString ? new Date(new Date(dateString).toDateString()) : null;
  }

  mapLogResponse(result: ApiResponse<UploadSummary[]>): UploadSummary[] {
    result?.data.forEach(res => {
      res._uploadStartTime = this.parseDateWithoutTime(res.upload_start_time)
      res._uploadEndTime = this.parseDateWithoutTime(res.upload_end_time)
    })
    return result?.data
  }

  ageInDays(d: Date): number {
    const DAY_IN_MILLISECOND = 86400000
    const now = new Date();
    return Math.floor((now.getTime() - d.getTime()) / DAY_IN_MILLISECOND)
  }

  timezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }

  dateDiffInSeconds(a: Date, b: Date) {
    const utc1 = Date.UTC(
      a.getFullYear(),
      a.getMonth(),
      a.getDate(),
      a.getHours(),
      a.getMinutes(),
      a.getSeconds()
    );
    const utc2 = Date.UTC(
      b.getFullYear(),
      b.getMonth(),
      b.getDate(),
      b.getHours(),
      b.getMinutes(),
      b.getSeconds()
    );
    return ((utc2 - utc1) / 1000);
  }

  getTransferRate(startDate: Date, sizeInBytes: number) {
    const fileSizeInMB = sizeInBytes / 131072;
    const totalTimeTaken = this.dateDiffInSeconds(startDate, new Date());
    const val = (totalTimeTaken && parseFloat((fileSizeInMB / totalTimeTaken).toFixed(1))) || 0
    return val ? `${val} Mbps` : '';
  }

  today(): Date {
    return new Date()
  }
  // To get part size, batchSize and total parts based on file size
  getPartDetails(filesize: number) {
    let filePartSizeAndTotalParts: any = {};
    let sourceFileSize = Number(filesize);
    const MULTIPART_DYNMC_FILE_SIZE_LIST: string =MULTIPART_DYNMC_FILE_SIZE_PROP
    let mp_dynm_prop_list = JSON.parse(MULTIPART_DYNMC_FILE_SIZE_LIST);

    for (var idx = 0; idx < mp_dynm_prop_list.length; idx++) {
      if (
        sourceFileSize >= mp_dynm_prop_list[idx].FileLLimit &&
        sourceFileSize <= mp_dynm_prop_list[idx].FileHLimit
      ) {
        filePartSizeAndTotalParts.partSize =
          Number(mp_dynm_prop_list[idx].partSize) * 1024 * 1024;
        filePartSizeAndTotalParts.totalParts = Math.ceil(
          sourceFileSize / (mp_dynm_prop_list[idx].partSize * 1024 * 1024)
        );
      }
      filePartSizeAndTotalParts.batchSize = mp_dynm_prop_list[idx].batchSize;
    }
    if (
      !filePartSizeAndTotalParts.partSize &&
      !filePartSizeAndTotalParts.totalParts
    ) {
      filePartSizeAndTotalParts.partSize = sourceFileSize;
      filePartSizeAndTotalParts.totalParts = 1;
      filePartSizeAndTotalParts.batchSize = 1;
    }
    return filePartSizeAndTotalParts;
  }

}
