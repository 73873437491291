import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { EnvService } from 'src/app/services/env/env.service';
import { TokenService } from 'src/app/services/token/token.service';
import { LoginInfo } from '../../models';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public offCanvasListData: any;
  public show = false;
  public showMob = false;
  public deskDevice = '';
  public mobDevice = '';
  public BusinessCategory: any;
  public companyName = 'abc'

  $subscriptions: Subscription[]
  userName = '';
  absSupportURL: string;

  constructor(
    private element: ElementRef,
    private auth: AuthService,
    private token: TokenService,
    private env: EnvService
  ) {
    this.$subscriptions = []
    this.absSupportURL = this.env.environment.hdrFtrhostUrl + '/global/dashboard';
  }

  ngOnInit() {
    this.$subscriptions.push(
      this.auth.$loginInfo.subscribe({
        next: (user: LoginInfo) => {
          this.userName = user?.name || user?.given_name || ''
        }
      })
    )
  }

  toggleOffCanvas(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (
      this.element.nativeElement.parentElement.classList.contains(
        'n-off-canvas-menu--open'
      )
    ) {
      this.element.nativeElement.parentElement.classList.remove(
        'n-off-canvas-menu--open'
      );
    } else {
      this.element.nativeElement.parentElement.classList.add(
        'n-off-canvas-menu--open'
      );
    }
  }

  toggleMenu(device: string, event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.deskDevice = device;
    this.show = !this.show;
  }

  toggleMenuMobile(device: string) {
    this.mobDevice = device;
    this.showMob = !this.showMob;
  }

  onBodyClick(event: any) {
    if (
      this.showMob
      && !event.target.classList.contains('n-property-bar__narrow-actions-dropdown-button')
      && this.mobDevice === 'mobile'
    ) {
      this.showMob = false;
    }

    if (
      this.show
      && !event.target.classList.contains('n-top-hat__link-text')
      && this.deskDevice === 'desktop'
    ) {
      this.show = false;
    }
  }

  logout() {
    this.token.invalidate()
    let url= this.env.environment.redirectUri
    this.auth.logout(url)
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => s.unsubscribe())
  }
}
