import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FileUploadComponent } from './pages/file-upload/file-upload.component';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';

import {
  HttpClientModule
} from "@angular/common/http";

import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/auth.interceptor';
import { CaseNumberComponent } from './pages/file-upload/components/case-number/case-number/case-number.component';
import { SelectFileComponent } from './pages/file-upload/components/select-file/select-file/select-file.component';
import { UploadInstructionComponent } from './pages/file-upload/components/upload-instruction/upload-instruction/upload-instruction.component';
import { UploadStatusMessageComponent } from './pages/file-upload/components/upload-message/upload-status-message/upload-status-message.component';
import { UploadSummaryComponent } from './pages/upload-summary/upload-summary.component';
import { SessionService } from './services/session/session.service';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { ProgressBarComponent } from './shared/components/progress-bar/progress-bar/progress-bar.component';
import { WaitSpinnerComponent } from './shared/components/wait-spinner/wait-spinner.component';
import { SummaryGridComponent } from './pages/upload-summary/summary-grid/summary-grid.component';
import { FilterPanelComponent } from './pages/upload-summary/filter-panel/filter-panel.component';
import { PaginationPanelComponent } from './pages/upload-summary/pagination-panel/pagination-panel.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';

function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json?v19597");
}

const routes: Routes = [
  { path: '', component: FileUploadComponent },
  { path: 'transData', component: UploadSummaryComponent },
  { path: 'accessDenied', component: AccessDeniedComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    WaitSpinnerComponent,
    FileUploadComponent,
    SafeHtmlPipe,
    CaseNumberComponent,
    SelectFileComponent,
    UploadInstructionComponent,
    ProgressBarComponent,
    UploadStatusMessageComponent,
    UploadSummaryComponent,
    SummaryGridComponent,
    FilterPanelComponent,
    PaginationPanelComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      },
      isolate: false
    }),
    RouterModule.forRoot(routes, { enableTracing: false, })
  ],
  providers: [SessionService, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  exports: [RouterModule]
})
export class AppModule { }
