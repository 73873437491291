import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { FILE_NAME_SPCL_CHAR, MAX_FILE_SIZE, SINGLE_UPLOAD_SIZE_LIMIT } from 'src/app/constants';
import { UploadService } from 'src/app/services/upload/upload.service';
import { EventParam, SelectedFile } from 'src/app/shared/models';

@Component({
  selector: 'app-select-file',
  templateUrl: './select-file.component.html',
  styleUrls: ['./select-file.component.css']
})
export class SelectFileComponent implements OnDestroy {
  @Output() afterValidate = new EventEmitter<SelectedFile>();
  @Input() isDisabled = true

  @ViewChild('uploadFileInput') fileInput: ElementRef<HTMLInputElement> | null = null;

  fileName = ''
  file: any = null
  contentType = 'binary/octet-stream'
  errorMsg = ''
  hasMultipart = false
  private $subscriptions: Subscription[]
  private eventsToReset = ['upload_success', 'upload_failed', 'sas_url_error', 'upload_cancelled']

  constructor(private upload: UploadService) {
    this.$subscriptions = [upload.$uploadStatus.subscribe((p: EventParam) => {
      if (this.eventsToReset.includes(p.type)) {
        this.resetSelection(true);
      }
    })]
  }

  private resetSelection(resetInput = false): void {
    if (resetInput && this.fileInput) {
      this.fileInput.nativeElement.value = ''
    }
    this.file = null;
    this.fileName = '';
  }

  private validateFile(files: FileList): boolean {
    if (!files?.length || !files[0]) {
      return false
    }
    if (files[0].size > MAX_FILE_SIZE) {
      this.errorMsg = "sfu_max_size_error";
      return false;
    }
    this.hasMultipart = files[0].size >= SINGLE_UPLOAD_SIZE_LIMIT
    this.fileName = files[0].name

    if (this.fileName.length) {
      if (this.fileName.indexOf(".") === 0) {
        this.fileName = this.fileName.slice(1);
      }
      const reg = new RegExp(FILE_NAME_SPCL_CHAR, "g");
      if (reg.test(this.fileName)) {
        this.fileName = this.fileName.replace(reg, "_");
      }
    }
    return !!this.fileName
  }

  whenFileSelect(event: any): void {
    this.resetSelection()

    if (!this.isDisabled && this.validateFile(event.target.files)) {
      this.file = event.target.files[0]
      this.contentType = this.file.type || 'binary/octet-stream';
      this.afterValidate.next({
        content: this.file,
        fileName: this.fileName,
        contentType: this.contentType,
        hasMultiparts: this.hasMultipart
      })
    }
  }

  ngOnDestroy(): void {
    this.$subscriptions.forEach(s => s.unsubscribe())
  }
}
