import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-pagination-panel',
  templateUrl: './pagination-panel.component.html',
  styleUrls: ['./pagination-panel.component.css']
})
export class PaginationPanelComponent implements OnInit {

  @Output()
  whenPaginate = new EventEmitter()

  pageSizeCtrl = new UntypedFormControl(10);
  currentPage = 1
  totalPage = 0
  private _dataCount = 0

  ngOnInit(): void {
    this.pageSizeCtrl.valueChanges.subscribe((v) => {
      this.currentPage = 1
      this.setTotalPage(parseInt(v))
      this.showPage(v)
    })
  }

  @Input()
  set dataCount(count: number) {
    this.currentPage = 1
    this._dataCount = count;
    this.setTotalPage(parseInt(this.pageSizeCtrl.value))
  }

  private setTotalPage(pageSize: number): void {
    this.totalPage = Math.floor(this._dataCount / pageSize) + (this._dataCount % pageSize ? 1 : 0)
  }

  showPage(pageSizeStr: string): void {
    const pageSize = parseInt(pageSizeStr)
    this.whenPaginate.emit({
      startIndex: (this.currentPage - 1) * pageSize,
      pageSize
    })
  }

  navigatePage(direction: number): void {
    this.currentPage = this.currentPage + direction;

    if (this.currentPage < 1) {
      this.currentPage = 1

    } else if (this.currentPage > this.totalPage) {
      this.currentPage = this.totalPage
    }
    this.showPage(this.pageSizeCtrl.value)
  }
}
