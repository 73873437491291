import { JWTPayload } from "jose"
import { Subject, Subscription } from "rxjs"
import { MAX_HTTP_REQUEST } from "../constants"
import { UploadService } from "../services/upload/upload.service"

export interface LoginInfo extends JWTPayload {
  allowFileUpload?: boolean,
  name?: string,
  securePerson?: string,
  uid?: string,
  given_name?: string
}

export type SelectedFile = {
  content: File | Blob,
  fileName: string,
  contentType: string,
  hasMultiparts?: boolean
}

export type UploadParam = {
  caseNumber: string,
  fileDetails: SelectedFile,
  loginInfo?: LoginInfo | null,
  partNumber?: number,
  uploadId?: string,
  partNumberList?: number[],
  unique_id?: string,
  maskedFileName?: string
}

export type EventParam = {
  type: 'upload_success' | 'upload_failed' | 'presigned_url_error' | 'upload_cancelled',
  value?: any
}

export type PreSignedUrl = {
  partNum: number,
  preSignedUrl: string,
  unique_id?: string,
  partIndex?: number,  // file part index start from 0
  maskedFileName?: string
}

export type PreSignedUrlModel = {
  totalParts: number,
  uploadId:string,
  preSignedUrls: PreSignedUrl[],
  uploadSummary?: any,
  unique_id?: string,
  maskedFileName?: string
}

export interface ApiResponse<T> {
  statusCode: number,
  data: T
}
export type multipartMap = { PartNumber: number, ETag: string }

export class MultipartUploadProgress {
  inProgressCount = 0
  multipartSas: PreSignedUrlModel
  successList: PreSignedUrl[] = []
  uploadedList:multipartMap[]=[]
  startDate: Date
  isResume = false
  sasUploadParam: UploadParam
  batchPartNumberList: any[] = []
  paused = false
  batchIndex=0

  //Holds subscription of file parts upload
  private $subscriptions: any = {}
  private currentIndex = 0;
  private uploadedCountOnResume = 0

  constructor(multipartSas: PreSignedUrlModel,sasUploadParam: UploadParam,batchPartNumberList: number[]) {
    this.updatePartIndex(multipartSas.preSignedUrls)
  this.sasUploadParam = sasUploadParam
  this.batchPartNumberList = batchPartNumberList
    this.multipartSas = multipartSas
    this.sasUploadParam.maskedFileName = multipartSas.maskedFileName
    this.sasUploadParam.uploadId = multipartSas.uploadId
    this.sasUploadParam.unique_id = multipartSas.unique_id
    this.startDate = new Date()
    this.paused=false
  this.batchIndex=0
    if (multipartSas?.uploadSummary) {
      const summary = multipartSas?.uploadSummary
      this.isResume = true;
      this.uploadedCountOnResume =summary.Parts.length || 0;
      this.uploadedList= summary.Parts;
    }
  }

  resetProgress(paused:boolean= false): void {
    this.paused = paused
    this.inProgressCount = 0;
    this.currentIndex = 0;
    Object.keys(this.$subscriptions).forEach(k => this.$subscriptions[k]?.unsubscribe())
    this.$subscriptions = {}
  }

  removeSubscription(key: number): void {
    delete this.$subscriptions[key];
  }

  addSubscription(key: number, subscription: Subscription): void {
    this.$subscriptions[key] = subscription
  }

  markSuccessOf(sas: PreSignedUrl,multipartMap:multipartMap): void {
    this.inProgressCount--;
    this.successList.push(sas);
    this.uploadedList.push({PartNumber: multipartMap.PartNumber, ETag: multipartMap.ETag})
    this.removeSubscription(sas.partNum);
    if (this.allSucceeded()) {
      this.resetProgress();
    }
  }

  get successCount(): number {
    return this.uploadedList.length
  }

  get totalCount(): number {
    return this.multipartSas.totalParts
  }

  canTriggerNext(): boolean {
    return this.inProgressCount < MAX_HTTP_REQUEST && this.successCount < this.totalCount
  }
  private updatePartIndex(sasUrls: PreSignedUrl[]): void {
    sasUrls.forEach((s) => {
      if (s.partNum !== undefined) {
        s.partIndex = s.partNum - 1;
      }
    });
  }
  nextItem(notify: Subject<any>, upload: UploadService): void {
    const setNext = () => {
      if (this.currentIndex >= this.totalCount || this.paused) {
        return;
      }
      const sas = this.multipartSas.preSignedUrls[this.currentIndex];

      if (sas) {
        this.currentIndex++;
        console.log(
          'uploadedCount: ',
          this.uploadedList.length,
          ' totalCount: ',
          this.multipartSas.totalParts
        );
        if (
          sas &&
          !this.uploadedList.some((s) => s.PartNumber === sas.partNum)
        ) {
          this.inProgressCount++;
          notify.next(sas);
          return;
        }

        setNext();
      } else {
        if (this.batchIndex < this.batchPartNumberList.length) {
          this.batchIndex++;
        }
        if (this.batchIndex < this.batchPartNumberList.length) {
          this.sasUploadParam.partNumberList =
            this.batchPartNumberList[this.batchIndex];
          upload.getSasUrls(this.sasUploadParam).subscribe({
            next: (sas: PreSignedUrlModel) => {
              this.updatePartIndex(sas.preSignedUrls);
              this.multipartSas.preSignedUrls.push(...sas.preSignedUrls);
              setNext();
            },
            error: () => {
              console.log('Error in getting presigned urls');
            },
          });
        }
      }
    };
    setNext();
  }

  allSucceeded(): boolean {
    return this.totalCount === this.successCount
  }

  uploadedSizeInCurrentSession(totalSize: number): number {
    const sizePerUpload = Math.floor(totalSize/this.totalCount)
    const totalUploadInCurrentSession = this.successCount - this.uploadedCountOnResume
    return sizePerUpload * totalUploadInCurrentSession
  }
  }

export enum UploadSummaryStatus {
  IN_PROGRESS = 'InProgress',
  PAUSED = 'Paused',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
  FAILED = 'Failed'

}
export type UploadSummary = {
  _uploadStartTime: Date | null,
  _uploadEndTime: Date | null,
  userId : string,
  filename: string,
  case_number: string,
  status : UploadSummaryStatus,
  upload_start_time: string,
  upload_end_time: string,
  upload_cancel_time: string,
  file_size : number,
  file_type: string,
  upload_transfer_rate: string,
  case_type: string,
  time_zone: string,
  sasUrls : string,
  sasurls?: string, // return from db as lowercase
  uploaded_blockId_list: string,
  uploaded_blockid_list?: string,  //return from db as lowercase
  total_parts: number,
  uploaded_parts: number
}

export type SasUrlPayload = {
  caseNumber: string,
  fileSize?: number,
  fileName: string,
  userId?: string
  partNumberList?: number[],
  uploadId?: string,
  unique_id?: string
  maskedFileName?: string
}

export type transDataPayload = {
  caseNumber?: string,
  fileName?: string,
  userId?: string,
  startDate?: Date,
  endDate?: Date,
}