<footer class="n-footer n-footer--v2" *ngIf="loggedIn">
  <div class="n-footer__top">
    <div class="n-container">
      <div class="n-footer__property-links">
        <h4 class="n-footer__property-link-list-header">More in Support</h4>
        <ul class="n-footer__property-link-list">
          <li class="n-footer__property-link-column">
            <ul class="n-footer__property-link-column-list">
              <li class="n-footer__property-link-list-item">
                <a
                  href="http://www.netapp.com/us/services-support/ngs-contacts.aspx"
                  class="n-footer__property-link"
                >
                  Contact Support
                </a>
              </li>
              <li class="n-footer__property-link-list-item">
                <a href="{{ absEServiceUrl }}" class="n-footer__property-link">
                  Report an Issue
                </a>
              </li>
              <li class="n-footer__property-link-list-item">
                <a href="{{ absEServiceUrl }}" class="n-footer__property-link">
                  Provide Feedback
                </a>
              </li>
              <li class="n-footer__property-link-list-item">
                <a
                  href="{{ absSupportAlertUrl }}"
                  class="n-footer__property-link"
                >
                  Support Alerts
                </a>
              </li>
              <li class="n-footer__property-link-list-item">
                <a
                  [type]="'ff'"
                  href="https://forums.netapp.com/docs/DOC-6948"
                  class="n-footer__property-link"
                >
                  System Change Calendar
                </a>
              </li>
            </ul>
          </li>
          <li class="n-footer__property-link-column">
            <ul class="n-footer__property-link-column-list">
              <li class="n-footer__property-link-list-item">
                <a
                  href="http://www.netapp.com/us/services-support/university/index.aspx"
                  class="n-footer__property-link"
                >
                  Training
                </a>
              </li>
              <li class="n-footer__property-link-list-item">
                <a
                  href="{{ absSupportOfferUrl }}"
                  class="n-footer__property-link"
                >
                  Support Offerings
                </a>
              </li>
              <li class="n-footer__property-link-list-item">
                <a
                  href="{{ absSupportPoliciesUrl }}"
                  class="n-footer__property-link"
                >
                  Support Policies
                </a>
              </li>
              <li class="n-footer__property-link-list-item">
                <a
                  href="{{ absSupportPolicyUrl }}"
                  class="n-footer__property-link"
                >
                  Browser Policy
                </a>
              </li>
              <li class="n-footer__property-link-list-item">
                <a
                  href="https://kb.netapp.com/Advice_and_Troubleshooting/Miscellaneous/NetApp_Authenticated_File_Upload_Release_Notes"
                  class="n-footer__property-link"
                >
                  NetApp Authenticated File Upload Release Notes
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="n-footer__corp-links">
        <ul class="n-footer__corp-link-list">
          <li class="n-footer__corp-link-list-header">
            <a
              href="https://www.netapp.com/us/company/index.aspx"
              class="n-footer__corp-link"
              >Company</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/company/about-netapp/index.aspx"
              class="n-footer__corp-link"
              >Our Story</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/company/news/index.aspx"
              class="n-footer__corp-link"
              >News@NetApp</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/company/events/index.aspx"
              class="n-footer__corp-link"
              >Events</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a href="http://www.netapp-insight.com/" class="n-footer__corp-link"
              >NetApp Insight</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/company/customer-stories/index.aspx"
              class="n-footer__corp-link"
              >Customer Stories</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a href="http://investors.netapp.com/" class="n-footer__corp-link"
              >Investors</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/careers/index.aspx"
              class="n-footer__corp-link"
              >Careers</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/contact-us/index.aspx"
              class="n-footer__corp-link"
              >Contact Us</a
            >
          </li>
        </ul>
        <ul class="n-footer__corp-link-list">
          <li class="n-footer__corp-link-list-header">
            <a
              href="http://www.netapp.com/us/forms/sales-contact.aspx"
              class="n-footer__corp-link"
              >Sales</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/how-to-buy/index.aspx"
              class="n-footer__corp-link"
              >How To Buy</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://solutionconnection.netapp.com/US/NorthAmerica/PartnerDirectory.aspx"
              class="n-footer__corp-link"
              >Find A Partner</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/how-to-buy/contracts/index.aspx"
              class="n-footer__corp-link"
              >US Public Sector Contracts</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/partners/become-a-partner/e-based-oems-list.aspx"
              class="n-footer__corp-link"
              >E-Based OEM Partners</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/how-to-buy/netapp-capital-solutions/index.aspx"
              class="n-footer__corp-link"
              >NetApp Capital Solutions</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/company/about-netapp/ebc/index.aspx"
              class="n-footer__corp-link"
              >Executive Briefing Center</a
            >
          </li>
        </ul>
        <ul class="n-footer__corp-link-list">
          <li class="n-footer__corp-link-list-header">
            <a
              href="https://www.netapp.com/us/legal/index.aspx"
              class="n-footer__corp-link"
              >Legal</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              [type]="'ff'"
              href="http://www.netapp.com/us/legal/privacypolicy/index.aspx"
              class="n-footer__corp-link"
              >Privacy &amp; Cookie Policy</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/legal/copyright.aspx"
              class="n-footer__corp-link"
              >Copyright</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/legal/terms-of-use.aspx"
              class="n-footer__corp-link"
              >Trademarks</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/legal/terms-of-use.aspx"
              class="n-footer__corp-link"
              >Community Terms of Use</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/legal/ca-senate-bill-657.aspx"
              class="n-footer__corp-link"
              >Slavery and Human Trafficking Statement</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/legal/accessibility.aspx"
              class="n-footer__corp-link"
              >Accessibility</a
            >
          </li>
        </ul>
        <ul class="n-footer__corp-link-list">
          <li class="n-footer__corp-link-list-header">Resources</li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/forms/subscriptions.aspx"
              class="n-footer__corp-link"
              >Subscriptions</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/library/index.aspx"
              class="n-footer__corp-link"
              >Library</a
            >
          </li>
          <li class="n-footer__corp-link-list-item">
            <a
              href="http://www.netapp.com/us/sitemap/index.aspx"
              class="n-footer__corp-link"
              >Site Map</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="n-footer__bottom">
    <div class="n-container">
      <div class="n-footer__bottom-left">
        <div class="n-footer__copyright">
          <a
            href="http://www.netapp.com/us/legal/copyright.aspx"
            class="n-footer__copyright-link"
          >
            © <span id="copyrightyear"> {{ fullYear }}</span>
            NetApp
          </a>
        </div>
        <div class="n-footer__social-links">
          <ul class="n-footer__social-link-list">
            <li class="n-footer__social-link-list-item" target="_blank">
              <a
                class="n-footer__social-link"
                data-ntap-social="follow-blog"
                href="http://blog.netapp.com"
                target="_blank"
                title="blog"
                alt="blog"
              >
                <span class="n-footer__social-link-text">blog</span>
                <svg
                  class="n-icon-blog n-footer__social-link-icon"
                  aria-labelledby="title"
                >
                  <title>blog</title>
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/assets/svg/sprite.svg#blog"
                  >
                    <svg
                      viewBox="0 0 15.5 15.6"
                      id="blog"
                      width="100%"
                      height="100%"
                    >
                      <path
                        d="M11.4 4.6zM15.8 8.3C15.4 4 12 .6 7.7.2c-.4 0-.7.3-.7.7v.3c0 .3.3.6.6.6 3.5.3 6.4 3.1 6.6 6.6 0 .3.3.6.6.6h.3c.4 0 .7-.3.7-.7z"
                      ></path>
                      <path
                        d="M7 4.1v.4c0 .3.2.6.5.6 1.7.2 3.1 1.6 3.3 3.3 0 .3.3.5.6.5h.4c.4 0 .7-.3.6-.7-.3-2.5-2.3-4.5-4.8-4.8-.3 0-.6.3-.6.7zM8.7 11.5c.1-.2.2-.5.3-.7.3-.5 1-2-.4-3.4S5.7 6.7 5.1 7l-.6.3c-.5.1-1 .1-1.3.1-.2 0-.3.2-.3.4-.2 3.4-1.9 5.4-2.5 6-.1.1-.1.3 0 .4l.1.1c.1.1.3.1.4 0l3.8-3.8c.1-.1.1-.2.1-.2-.1-.3 0-.6.2-.9.5-.4 1.2-.4 1.6 0 .4.4.4 1.1 0 1.6-.3.3-.6.4-.9.3-.1 0-.2 0-.2.1l-3.8 3.8c-.1.1-.1.3 0 .4l.1.1c.1.1.3.1.4 0 .6-.6 2.6-2.4 6-2.5.2 0 .4-.2.4-.4 0-.3 0-.8.1-1.3z"
                      ></path>
                    </svg>
                  </use>
                </svg>
              </a>
            </li>
            <li class="n-footer__social-link-list-item" target="_blank">
              <a
                class="n-footer__social-link"
                data-ntap-social="follow-community"
                href="http://community.netapp.com/"
                target="_blank"
                title="community"
                alt="community"
              >
                <span class="n-footer__social-link-text">community</span>
                <svg
                  class="n-icon-community n-footer__social-link-icon"
                  aria-labelledby="title"
                >
                  <title>community</title>
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/assets/svg/sprite.svg#comments"
                  >
                    <svg
                      viewBox="0 0 511.63 402.18"
                      id="comments"
                      width="100%"
                      height="100%"
                    >
                      <path
                        d="M301.927 327.605c30.926-13.038 55.34-30.785 73.23-53.248 17.888-22.458 26.833-46.915 26.833-73.372 0-26.458-8.945-50.917-26.84-73.376-17.888-22.459-42.298-40.208-73.228-53.249-30.93-13.039-64.571-19.556-100.928-19.556-36.354 0-69.995 6.521-100.927 19.556-30.929 13.04-55.34 30.789-73.229 53.249C8.947 150.072 0 174.527 0 200.986c0 22.648 6.767 43.975 20.28 63.96 13.512 19.981 32.071 36.829 55.671 50.531-1.902 4.572-3.853 8.754-5.852 12.566a72.375 72.375 0 0 1-7.139 10.991 984.53 984.53 0 0 0-6.423 8.275c-1.523 1.998-3.997 4.812-7.425 8.422-3.427 3.617-5.617 5.996-6.567 7.135 0-.191-.381.24-1.143 1.287-.763 1.047-1.191 1.52-1.285 1.431-.096-.103-.477.373-1.143 1.42l-1 1.571-.715 1.423a6.114 6.114 0 0 0-.57 1.712c-.096.567-.144 1.19-.144 1.854s.094 1.28.288 1.854c.381 2.471 1.475 4.466 3.283 5.996 1.807 1.52 3.756 2.279 5.852 2.279h.857c9.515-1.332 17.701-2.854 24.552-4.569 29.312-7.61 55.771-19.797 79.372-36.545 17.129 3.046 33.879 4.568 50.247 4.568 36.357.013 70.002-6.502 100.931-19.542z"
                      ></path>
                      <path
                        d="M491.354 338.166c13.518-19.889 20.272-41.247 20.272-64.09 0-23.414-7.146-45.316-21.416-65.68-14.277-20.362-33.694-37.305-58.245-50.819 4.374 14.274 6.563 28.739 6.563 43.398 0 25.503-6.368 49.676-19.129 72.519-12.752 22.836-31.025 43.01-54.816 60.524-22.08 15.988-47.205 28.261-75.377 36.829-28.164 8.562-57.573 12.848-88.218 12.848-5.708 0-14.084-.377-25.122-1.137 38.256 25.119 83.177 37.685 134.756 37.685 16.371 0 33.119-1.526 50.251-4.571 23.6 16.755 50.06 28.931 79.37 36.549 6.852 1.718 15.037 3.237 24.554 4.568 2.283.191 4.381-.476 6.283-1.999 1.903-1.522 3.142-3.61 3.71-6.272-.089-1.143 0-1.77.287-1.861.281-.09.233-.712-.144-1.852-.376-1.144-.568-1.715-.568-1.715l-.712-1.424a13.68 13.68 0 0 0-.999-1.567c-.476-.66-.855-1.143-1.143-1.427-.28-.284-.705-.763-1.28-1.424-.568-.66-.951-1.092-1.143-1.283-.951-1.143-3.139-3.521-6.564-7.139-3.429-3.613-5.899-6.42-7.422-8.418a867.888 867.888 0 0 0-6.424-8.282 72.209 72.209 0 0 1-7.139-10.991c-1.998-3.806-3.949-7.995-5.852-12.56 23.602-13.716 42.156-30.513 55.667-50.409z"
                      ></path>
                    </svg>
                  </use>
                </svg>
              </a>
            </li>
            <li class="n-footer__social-link-list-item" target="_blank">
              <a
                class="n-footer__social-link"
                data-ntap-social="follow-twitter"
                href="https://twitter.com/netapp"
                target="_blank"
                title="twitter"
                alt="twitter"
              >
                <span class="n-footer__social-link-text">twitter</span>
                <svg
                  class="n-icon-twitter n-footer__social-link-icon"
                  aria-labelledby="title"
                >
                  <title>twitter</title>
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/assets/svg/sprite.svg#twitter"
                  >
                    <svg
                      viewBox="0 0 449.96 365.44"
                      id="twitter"
                      width="100%"
                      height="100%"
                    >
                      <path
                        d="M449.956 85.657c-17.702 7.614-35.408 12.369-53.102 14.279 19.985-11.991 33.503-28.931 40.546-50.819-18.281 10.847-37.787 18.268-58.532 22.267-18.274-19.414-40.73-29.125-67.383-29.125-25.502 0-47.246 8.992-65.24 26.98-17.984 17.987-26.977 39.731-26.977 65.235 0 6.851.76 13.896 2.284 21.128-37.688-1.903-73.042-11.372-106.068-28.407C82.46 110.158 54.433 87.46 31.403 59.101c-8.375 14.272-12.564 29.787-12.564 46.536 0 15.798 3.711 30.456 11.138 43.97 7.422 13.512 17.417 24.455 29.98 32.831-14.849-.572-28.743-4.475-41.684-11.708v1.142c0 22.271 6.995 41.824 20.983 58.674 13.99 16.848 31.645 27.453 52.961 31.833a95.543 95.543 0 0 1-24.269 3.138c-5.33 0-11.136-.475-17.416-1.42 5.9 18.459 16.75 33.633 32.546 45.535 15.799 11.896 33.691 18.028 53.677 18.418-33.498 26.262-71.66 39.393-114.486 39.393-8.186 0-15.607-.373-22.27-1.139 42.827 27.596 90.03 41.394 141.612 41.394 32.738 0 63.478-5.181 92.21-15.557 28.746-10.369 53.297-24.267 73.665-41.686 20.362-17.415 37.925-37.448 52.674-60.097 14.75-22.651 25.738-46.298 32.977-70.946 7.23-24.653 10.848-49.344 10.848-74.092 0-5.33-.096-9.325-.287-11.991 18.087-13.127 33.504-29.023 46.258-47.672z"
                      ></path>
                    </svg>
                  </use>
                </svg>
              </a>
            </li>
            <li class="n-footer__social-link-list-item" target="_blank">
              <a
                class="n-footer__social-link"
                data-ntap-social="follow-facebook"
                href="http://www.facebook.com/NetApp"
                target="_blank"
                title="facebook"
                alt="facebook"
              >
                <span class="n-footer__social-link-text">facebook</span>
                <svg
                  class="n-icon-facebook n-footer__social-link-icon"
                  aria-labelledby="title"
                >
                  <title>facebook</title>
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/assets/svg/sprite.svg#facebook"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      id="facebook"
                      width="100%"
                      height="100%"
                    >
                      <path
                        d="M1343 12v264h-157q-86 0-116 36t-30 108v189h293l-39 296h-254v759H734V905H479V609h255V391q0-186 104-288.5T1115 0q147 0 228 12z"
                      ></path>
                    </svg>
                  </use>
                </svg>
              </a>
            </li>
            <li class="n-footer__social-link-list-item" target="_blank">
              <a
                class="n-footer__social-link"
                data-ntap-social="follow-linkedin"
                href="http://www.linkedin.com/company/netapp"
                target="_blank"
                title="linkedin"
                alt="linkedin"
              >
                <span class="n-footer__social-link-text">linkedin</span>
                <svg
                  class="n-icon-linkedin n-footer__social-link-icon"
                  aria-labelledby="title"
                >
                  <title>linkedin</title>
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/assets/svg/sprite.svg#linkedin"
                  >
                    <svg
                      viewBox="0 0 438.54 419.12"
                      id="linkedin"
                      width="100%"
                      height="100%"
                    >
                      <path
                        d="M5.424 145.895H99.64v282.932H5.424zM408.842 171.739c-19.791-21.604-45.967-32.408-78.512-32.408-11.991 0-22.891 1.475-32.695 4.427-9.801 2.95-18.079 7.089-24.838 12.419-6.755 5.33-12.135 10.278-16.129 14.844-3.798 4.337-7.512 9.389-11.136 15.104v-40.232h-93.935l.288 13.706c.193 9.139.288 37.307.288 84.508 0 47.205-.19 108.777-.572 184.722h93.931V270.942c0-9.705 1.041-17.412 3.139-23.127 4-9.712 10.037-17.843 18.131-24.407 8.093-6.572 18.13-9.855 30.125-9.855 16.364 0 28.407 5.662 36.117 16.987 7.707 11.324 11.561 26.98 11.561 46.966V428.82h93.931V266.664c-.007-41.688-9.897-73.328-29.694-94.925zM53.103 9.708c-15.796 0-28.595 4.619-38.4 13.848C4.899 32.787 0 44.441 0 58.529 0 72.42 4.758 84.034 14.275 93.358c9.514 9.325 22.078 13.99 37.685 13.99h.571c15.99 0 28.887-4.661 38.688-13.99 9.801-9.324 14.606-20.934 14.417-34.829-.19-14.087-5.047-25.742-14.561-34.973C81.562 14.323 68.9 9.708 53.103 9.708z"
                      ></path>
                    </svg>
                  </use>
                </svg>
              </a>
            </li>
            <li class="n-footer__social-link-list-item" target="_blank">
              <a
                class="n-footer__social-link"
                data-ntap-social="follow-youtube"
                href="http://www.youtube.com/user/NetAppTV"
                target="_blank"
                title="youtube"
                alt="youtube"
              >
                <span class="n-footer__social-link-text">youtube</span>
                <svg
                  class="n-icon-youtube n-footer__social-link-icon"
                  aria-labelledby="title"
                >
                  <title>youtube</title>
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/assets/svg/sprite.svg#youtube"
                  >
                    <svg
                      viewBox="0 0 423.13 511.63"
                      id="youtube"
                      width="100%"
                      height="100%"
                    >
                      <path
                        d="M459.954 264.376c-2.471-11.233-7.949-20.653-16.416-28.264-8.474-7.611-18.227-12.085-29.27-13.418-35.02-3.806-87.837-5.708-158.457-5.708-70.618 0-123.341 1.903-158.174 5.708-11.227 1.333-21.029 5.807-29.407 13.418-8.376 7.614-13.896 17.035-16.562 28.264-4.948 22.083-7.423 55.391-7.423 99.931 0 45.299 2.475 78.61 7.423 99.93 2.478 11.225 7.951 20.653 16.421 28.261 8.47 7.614 18.225 11.991 29.263 13.134 35.026 3.997 87.847 5.996 158.461 5.996 70.609 0 123.44-1.999 158.453-5.996 11.043-1.143 20.748-5.52 29.126-13.134 8.377-7.607 13.897-17.036 16.56-28.261 4.948-22.083 7.426-55.391 7.426-99.93-.001-45.3-2.479-78.612-7.424-99.931zm-294.929 28.842h-30.549v162.45h-28.549v-162.45h-29.98v-26.837h89.079v26.837zm77.085 162.45H216.7v-15.421c-10.278 11.615-19.989 17.419-29.125 17.419-8.754 0-14.275-3.524-16.556-10.564-1.521-4.568-2.286-11.519-2.286-20.844V314.627h25.41v103.924c0 6.088.096 9.421.288 9.993.571 3.997 2.568 5.995 5.996 5.995 5.138 0 10.566-3.997 16.274-11.991V314.627h25.41v141.041zm97.073-42.257c0 13.894-.855 23.417-2.56 28.558-3.244 10.462-9.996 15.697-20.273 15.697-9.137 0-17.986-5.235-26.556-15.697v13.702h-25.406v-189.29h25.406v61.955c8.189-10.273 17.036-15.413 26.556-15.413 10.277 0 17.029 5.331 20.273 15.988 1.704 4.948 2.56 14.369 2.56 28.264v56.236zm96.502-23.408h-51.104v24.839c0 13.134 4.374 19.697 13.131 19.697 6.279 0 10.089-3.422 11.42-10.28.376-1.902.571-7.706.571-17.412h25.981v3.71c0 9.329-.195 14.846-.572 16.563-.567 5.133-2.56 10.273-5.995 15.413-6.852 10.089-17.139 15.133-30.841 15.133-13.127 0-23.407-4.855-30.833-14.558-5.517-7.043-8.275-18.083-8.275-33.12v-49.396c0-15.036 2.662-26.076 7.987-33.119 7.427-9.705 17.61-14.558 30.557-14.558 12.755 0 22.85 4.853 30.263 14.558 5.146 7.043 7.71 18.083 7.71 33.119v29.411z"
                      ></path>
                      <path
                        d="M302.634 336.043c-4.38 0-8.658 2.101-12.847 6.283v85.934c4.188 4.186 8.467 6.279 12.847 6.279 7.419 0 11.14-6.372 11.14-19.13v-60.236c-.001-12.755-3.713-19.13-11.14-19.13zM397.428 336.043c-8.565 0-12.847 6.475-12.847 19.41v13.134h25.693v-13.134c0-12.942-4.284-19.41-12.846-19.41zM148.473 113.917v77.375h28.549v-77.375L211.563 0h-29.121l-19.41 75.089L142.759 0h-30.262c5.33 15.99 11.516 33.785 18.559 53.391 8.947 26.265 14.749 46.444 17.417 60.526zM249.82 193.291c13.134 0 23.219-4.854 30.262-14.561 5.332-7.043 7.994-18.274 7.994-33.689V95.075c0-15.225-2.669-26.363-7.994-33.406-7.043-9.707-17.128-14.561-30.262-14.561-12.756 0-22.75 4.854-29.98 14.561-5.327 7.043-7.992 18.181-7.992 33.406v49.965c0 15.225 2.662 26.457 7.992 33.689 7.233 9.708 17.223 14.562 29.98 14.562zM237.541 89.935c0-13.134 4.093-19.701 12.279-19.701s12.275 6.567 12.275 19.701v59.955c0 13.328-4.089 19.985-12.275 19.985s-12.279-6.661-12.279-19.985V89.935zM328.328 193.291c9.523 0 19.328-5.901 29.413-17.705v15.703h25.981V48.822h-25.981v108.777c-5.712 8.186-11.133 12.275-16.279 12.275-3.429 0-5.428-2.093-5.996-6.28-.191-.381-.287-3.715-.287-9.994V48.822h-25.981v112.492c0 9.705.767 16.84 2.286 21.411 2.477 7.043 8.09 10.566 16.844 10.566z"
                      ></path>
                    </svg>
                  </use>
                </svg>
              </a>
            </li>
            <li class="n-footer__social-link-list-item" target="_blank">
              <a
                class="n-footer__social-link"
                data-ntap-social="follow-slideshare"
                href="http://www.slideshare.net/NetApp"
                target="_blank"
                title="slideshare"
                alt="slideshare"
              >
                <span class="n-footer__social-link-text">slideshare</span>
                <svg
                  class="n-icon-slideshare n-footer__social-link-icon"
                  aria-labelledby="title"
                >
                  <title>slideshare</title>
                  <use
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    xlink:href="/assets/svg/sprite.svg#slideshare"
                  >
                    <svg
                      viewBox="0 0 1770 1801.33"
                      id="slideshare"
                      width="100%"
                      height="100%"
                    >
                      <path
                        d="M873 740q0 83-63.5 142.5T657 942t-152.5-59.5T441 740q0-84 63.5-143T657 538t152.5 59T873 740zm502 0q0 83-63 142.5T1159 942q-89 0-152.5-59.5T943 740q0-84 63.5-143t152.5-59q90 0 153 59t63 143zm225 180V253q0-87-32-123.5T1457 93H345q-83 0-112.5 34T203 253v673q43 23 88.5 40t81 28 81 18.5 71 11 70 4 58.5.5 56.5-2 44.5-2q68-1 95 27 6 6 10 9 26 25 61 51 7-91 118-87 5 0 36.5 1.5t43 2 45.5 1 53-1 54.5-4.5 61-8.5 62-13.5 67-19.5 67.5-27 72-34.5zm163-5q-121 149-372 252 84 285-23 465-66 113-183 148-104 32-182-15-86-51-82-164l-1-326v-1q-8-2-24.5-6t-23.5-5l-1 338q4 114-83 164-79 47-183 15-117-36-182-150-105-180-22-463-251-103-372-252-25-37-4-63t60 1q3 2 11 7t11 8V174q0-72 47-123T268 0h1257q67 0 114 51t47 123v694l21-15q39-27 60-1t-4 63z"
                      ></path>
                    </svg>
                  </use>
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="n-footer__bottom-right">
        <p class="n-footer__fine-print">
          Have feedback for our website?
          <a href="http://www.netapp.com/us/forms/site-feedback.aspx"
            >Let us know</a
          >
        </p>
      </div>
    </div>
  </div>
</footer>
