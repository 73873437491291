<div class="progress-bar-container">
  <div>{{progressText() | translate}}</div>
  <div class="progress">
    <div
    class="progress-bar"
      [ngClass]="{'progress-bar-striped': !completed, 'active': !paused}"
      role="progressbar"
      aria-valuenow="40"
      aria-valuemin="0"
      aria-valuemax="100"
      [ngStyle]="{ width: percentage + '%' }"
    ></div>
    <span [ngClass]="{ 'text-col': percentage > 53 }">{{ percentage + '%'}}</span>
  </div>
  <div class="pause-cancel-container">
    <input
    *ngIf="showInterruption('pause')"
    type="button"
    class="button button--small fa"
    [value]="(paused?'Resume':'Pause') | translate"
    (click)="confirmPause.showModal()"
    style="text-transform: none"
  />
  <input
    *ngIf="showInterruption('cancel')"
    type="button"
    class="button button--small fa"
    [value]="'Cancel' | translate"
    (click)="confirmationCancel.showModal()"
    style="text-transform: none"
  />
</div>
</div>

<dialog #confirmPause class="confirm-dialog">
  <div class="body">
    {{(paused ? "sfu_confirm_resume" : "sfu_confirm_pause") | translate}}
  </div>
  <div class="footer">
    <button (click)="confirmPause.close();pauseProgress(!paused)">{{"sfu_yes" | translate}}</button>
    <button (click)="confirmPause.close();">{{"sfu_no" | translate}}</button>
  </div>
</dialog>

<dialog #confirmationCancel class="confirm-dialog">
  <div class="body">
    {{"sfu_confirm_cancel" | translate}}
  </div>
  <div class="footer">
    <button (click)="confirmationCancel.close();cancelProgress()">{{"sfu_yes" | translate}}</button>
    <button (click)="confirmationCancel.close()">{{"sfu_no" | translate}}</button>
  </div>
</dialog>
