import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils/utils.service';

@Component({
  selector: 'app-filter-panel',
  templateUrl: './filter-panel.component.html',
  styleUrls: ['./filter-panel.component.css']
})
export class FilterPanelComponent {
  @Input()
  fileTypes: string[] = []

  @Output()
  whenFilter = new EventEmitter()

  startDateCtrl = new UntypedFormControl('');
  endDateCtrl = new UntypedFormControl('');
  caseNumberCtrl = new UntypedFormControl('');
  fileNameCtrl = new UntypedFormControl('');

  constructor(private util: UtilsService) {}

  private getFilterParam(): any {
    return {
      startDate: this.util.parseDateWithoutTime(this.startDateCtrl.value),
      endDate: this.util.parseDateWithoutTime(this.endDateCtrl.value),
      caseNumber: this.caseNumberCtrl.value,
      fileName: this.fileNameCtrl.value
    }
  }

  private validate(): boolean {
    return !this.invalidStartEndDate
  }

  filter(): void {
    if (this.validate()) {
      this.whenFilter.emit(this.getFilterParam())
    }
  }

  get invalidStartEndDate(): boolean {
    const startDate = this.util.parseDateWithoutTime(this.startDateCtrl.value)
    const endDate = this.util.parseDateWithoutTime(this.endDateCtrl.value)
    return !!(startDate && endDate && startDate > endDate)
  }
}
