export const TOKEN = 'token'
export const CODE = 'code'
export const REFRESH_COUNT = 'refreshCount'
export const MAX_TOKEN_REFRESH_TRY = 3
export const USER_INFO = 'userInfo'
export const MAX_FILE_SIZE = 1024 * 1024 * 1024 * 800 // 800 GiB
export const SINGLE_UPLOAD_SIZE_LIMIT = 1024 * 1024 * 100 // 100 MiB
export const FILE_NAME_SPCL_CHAR = '[#$%&*@! ]'
export const MAX_HTTP_REQUEST = 5
export const AUTHORIZATION = 'authorization'
export const SECURED_CASE_TYPES = ['secure technical case', 'secure technical parts']
export const MULTIPART_DYNMC_FILE_SIZE_PROP: string =
'[{"FileLLimit" : 751619276800, "FileHLimit" : 858993459200, "partSize": 90,"batchSize": 4 },{"FileLLimit" : 644245094400, "FileHLimit" : 751619276799,"partSize": 80, "batchSize": 5 },{"FileLLimit" : 483183820800, "FileHLimit": 644245094399, "partSize": 70, "batchSize": 6 },{"FileLLimit" :408021893120, "FileHLimit" : 483183820799, "partSize": 50, "batchSize": 8},{"FileLLimit" : 268435456000, "FileHLimit" : 408021893119, "partSize": 40,"batchSize": 10 },{"FileLLimit" : 107374182400, "FileHLimit" : 268435455999,"partSize": 30, "batchSize": 14 },{"FileLLimit" : 104857600, "FileHLimit" :107374182399, "partSize": 20, "batchSize": 20 }]';
