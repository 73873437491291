import { Component } from '@angular/core';

@Component({
  selector: 'app-upload-instruction',
  templateUrl: './upload-instruction.component.html',
  styleUrls: ['./upload-instruction.component.css']
})
export class UploadInstructionComponent {

}
