<label for="txtCaseValidation" class="n-form__label case-number-label" style="text-transform: none;margin-top: 10.5px;">
  <span>{{ 'sfu_case_no_label_text' | translate }}</span>
  <span style="margin-left: 10.5px;" class="tooltip">
    <svg class="n-icon--default" aria-labelledby="title">
      <use xlink:href="/assets/svg/sprite.svg#question-circle">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 14" id="question-circle">
          <path
            d="M7 10.75v-1.5q0-.109-.07-.18T6.75 9h-1.5q-.109 0-.18.07T5 9.25v1.5q0 .109.07.18t.18.07h1.5q.109 0 .18-.07t.07-.18zM9 5.5q0-.688-.434-1.273t-1.082-.906-1.328-.32q-1.898 0-2.898 1.664-.117.187.062.328l1.031.781q.055.047.148.047.125 0 .195-.094.414-.531.672-.719.266-.187.672-.187.375 0 .668.203t.293.461q0 .297-.156.477t-.531.352q-.492.219-.902.676T5 7.97v.281q0 .109.07.18t.18.07h1.5q.109 0 .18-.07t.07-.18q0-.148.168-.387t.426-.387q.25-.141.383-.223t.359-.273.348-.375.219-.473.098-.633zM12 7q0 1.633-.805 3.012t-2.184 2.184-3.012.805-3.012-.805-2.184-2.184T-.002 7t.805-3.012 2.184-2.184T5.999.999t3.012.805 2.184 2.184T12 7z">
          </path>
        </svg>
      </use>
    </svg>
    <span class="tooltiptext">{{ 'sfu_case_no_tooltip_text' | translate }}</span>
  </span>
</label>

<input class="sfu-form__custom_input" 
  [ngClass]="{' sfu-form__custom_input--validate ': enableCaseBtn}" 
  type="text" placeholder="10 numeric characters only" maxlength="10"
  [formControl]="caseNumberCtrl"
  style="display: block; float: left;" />

<button 
  type="button"
  class="button button--small validate-button"
  [disabled]="caseNumberCtrl.invalid || loading"
  (click)="validate()">
  {{ 'sfu_validate_button_text' | translate }}
</button>
<app-wait-spinner *ngIf="loading"></app-wait-spinner>
<div *ngIf="errorMsg" class="error-msg alert alert-danger alert-progress">
  <!-- <a *ngIf="errorMsg === 'sfu_not_a_secured_case'"
    [href]="uploadDashboardUrl()"
    class="alert-danger">
    <strong>{{errorMsg | translate}}</strong>
  </a> -->
  <strong *ngIf="errorMsg !== 'sfu_not_a_secured_case'">{{errorMsg | translate}}</strong>
</div>