<div *ngIf="uploadStatus === 'success'" class="alert alert-success alert-progress">
  <strong>{{ uploadMessage  }}</strong>
</div>
<div *ngIf="uploadStatus === 'failed'" class="alert alert-danger alert-progress">
  <strong>{{ uploadMessage  }}</strong>
</div>
<div *ngIf="uploadStatus === 'sas_error'" class="alert alert-danger alert-progress">
  <strong>{{"sfu_sas_url_error" | translate}}</strong>
</div>
<div *ngIf="uploadStatus === 'cancelled'" class="alert alert-danger alert-progress">
  <strong>{{ uploadMessage  }}</strong>
</div>
